import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import { formatFeeHandler, getDigitsAfterDecimal } from "../../../../../components/helper";
import { Link } from "react-router-dom";

const takerFee = parseFloat(process.env.REACT_APP_TAKER_FEE);
const makerFee = parseFloat(process.env.REACT_APP_MAKER_FEE);
const spread = parseFloat(process.env.REACT_APP_ORDER_SPREAD);

const Action = ({
  title,
  limit,
  classButton,
  orderType,
  orderData,
  slug,
  currentMarketPrice,
}) => {
  const [buyLimitPrice, setBuyLimitPrice] = useState("");
  const [sellLimitPrice, setSellLimitPrice] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [buyWarning, setBuyWarning] = useState("");
  const [sellWarning, setSellWarning] = useState("");
  const [buyTotal, setBuyTotal] = useState("");
  const [sellTotal, setSellTotal] = useState("");
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    setBuyAmount("");
    setSellAmount("");
    setBuyWarning("");
    setSellWarning("");
    setBuyTotal("");
    setSellTotal("");
  }, [slug, orderData]);

  useEffect(() => {
    setFlag(true);
  }, [slug]);

  useEffect(() => {
    if (flag && currentMarketPrice) {
      setBuyLimitPrice(currentMarketPrice);
      setSellLimitPrice(currentMarketPrice);
      setFlag(false);
    }
  }, [flag, currentMarketPrice]);

  const handleBuyAmountChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setBuyAmount(value);

      if (value === "") {
        setBuyWarning("");
        setBuyTotal("");
      }
      else if (parseFloat(value) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setBuyWarning(`Amount should be greater than or equal to ${process.env.REACT_APP_MINIMUM_ORDER_AMOUNT} ${slug?.split("-")?.[1]?.toUpperCase()}.`)
      }
      else if (parseFloat(value) <= 0) {
        setBuyWarning("Amount must be greater than 0");
      }
      else if (parseFloat(value) > parseFloat(orderData?.currencyBalance)) {
        setBuyWarning("Insufficient balance");
        setBuyTotal("0.00");
      }
      else if (parseFloat(value) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setBuyWarning(`Minimum amount should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
        setBuyTotal("");
      }
      else {
        setBuyWarning("");

        let fiatAmount = (1 - (takerFee / 100)) * parseFloat(value);
        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(fiatAmount);
        let fiatSpread = parseFloat(fiatAmount) - parseFloat(fiatSpreadCalculation);

        let cryptoSpread = parseFloat(fiatSpread) / parseFloat(buyLimitPrice);
        let appCryptoTotal = parseFloat(fiatAmount) / parseFloat(buyLimitPrice);
        let customerCryptoTotal = (parseFloat(appCryptoTotal) - parseFloat(cryptoSpread));
        setBuyTotal(customerCryptoTotal ? getDigitsAfterDecimal(customerCryptoTotal, orderData?.current_market_details?.[0]?.amountDecimalPrecision) : "");
      }
    }
  };

  const handleSellAmountChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setSellAmount(value);

      if (value === "") {
        setSellWarning("");
        setSellTotal("");
      }
      else if (parseFloat(value) <= 0) {
        setSellWarning("Amount must be greater than 0");
      }
      else if (parseFloat(value) > parseFloat(orderData?.coinBalance)) {
        setSellWarning("Insufficient balance");
        setSellTotal("0.00");
      }
      else if ((parseFloat(value) * parseFloat(sellLimitPrice)) < parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
        setSellWarning(`Minimum amount to sell should be more than or equal to ${parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)} ${slug.split("-")[1]}.`);
        setSellTotal("");
      }
      else {
        setSellWarning("");

        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(sellLimitPrice);
        let fiatSpread = parseFloat(sellLimitPrice) - parseFloat(fiatSpreadCalculation);
        let cryptoAmount = (1 - (makerFee / 100)) * parseFloat(value);
        let appFiatTotal = parseFloat(sellLimitPrice) * parseFloat(cryptoAmount);
        let customerFiatTotal = parseFloat(appFiatTotal) - parseFloat(fiatSpread);

        setSellTotal(customerFiatTotal ? getDigitsAfterDecimal(customerFiatTotal, orderData?.current_market_details?.[0]?.priceDecimalPrecision) : "");
      }
    }
  };

  const handleBuyLimitPriceChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setBuyLimitPrice(value);

      if (value === "") {
        setBuyWarning("");
        setBuyTotal("");
      }
      else if (parseFloat(value) <= 0) {
        setBuyWarning("Limit Price must be greater than 0");
      }
      else {
        setBuyWarning("");

        let fiatAmount = (1 - (takerFee / 100)) * parseFloat(buyAmount);
        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(fiatAmount);
        let fiatSpread = parseFloat(fiatAmount) - parseFloat(fiatSpreadCalculation);

        let cryptoSpread = parseFloat(fiatSpread) / parseFloat(value);
        let appCryptoTotal = parseFloat(fiatAmount) / parseFloat(value);
        let customerCryptoTotal = (parseFloat(appCryptoTotal) - parseFloat(cryptoSpread));
        setBuyTotal(customerCryptoTotal ? getDigitsAfterDecimal(customerCryptoTotal, orderData?.current_market_details?.[0]?.amountDecimalPrecision) : "");
      }
    }
  };

  const handleSellLimitPriceChange = (value) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value) || value === "") {
      setSellLimitPrice(value);

      if (value === "") {
        setSellWarning("");
        setSellTotal("");
      }
      else if (parseFloat(value) <= 0) {
        setSellWarning("Limit Price must be greater than 0");
      }
      else {
        setSellWarning("");

        let fiatSpreadCalculation = (1 - (spread / 100)) * parseFloat(value);
        let fiatSpread = parseFloat(value) - parseFloat(fiatSpreadCalculation);
        let cryptoAmount = (1 - (makerFee / 100)) * parseFloat(sellAmount);
        let appFiatTotal = parseFloat(value) * parseFloat(cryptoAmount);
        let customerFiatTotal = parseFloat(appFiatTotal) - parseFloat(fiatSpread);

        setSellTotal(customerFiatTotal ? getDigitsAfterDecimal(customerFiatTotal, orderData?.current_market_details?.[0]?.priceDecimalPrecision) : "");
      }
    }
  };

  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{title}</div>
      </div>
      {limit ? (
        orderType?.toLowerCase() === "buy" ? (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Limit Price</div>
              <input
                className={styles.input}
                type="text"
                name="price"
                required
                value={buyLimitPrice}
                placeholder="0.00"
                onChange={(e) => handleBuyLimitPriceChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input
                className={styles.input}
                type="text"
                name="amount"
                required
                value={buyAmount}
                placeholder="0.00"
                onChange={(e) => handleBuyAmountChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{buyWarning}</span>
            </div>
            <label className={styles.field}>
              <div className={styles.label}>Total</div>
              <input
                className={styles.input}
                type="text"
                name="total"
                required
                disabled={true}
                defaultValue={buyTotal}
                placeholder="0.00"
              />
              <div className={styles.currency}>{slug?.split("-")?.[0]?.toUpperCase()}</div>
            </label>
            <div className={styles.estFee}>
              <div className={styles.titleFeeText}>Est. Fee(≈)</div>
              <div className={styles.titleFee}>0.00 <small>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</small></div>
            </div>
          </>
        ) : (
          <>
            <label className={styles.field}>
              <div className={styles.label}>Limit Price</div>
              <input
                className={styles.input}
                type="text"
                name="price"
                required
                value={sellLimitPrice}
                placeholder="0.00"
                onChange={(e) => handleSellLimitPriceChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <label className={styles.field}>
              <div className={styles.label}>Amount</div>
              <input
                className={styles.input}
                type="text"
                name="amount"
                required
                value={sellAmount}
                placeholder="0.00"
                onChange={(e) => handleSellAmountChange(e.target.value)}
              />
              <div className={styles.currency}>{slug?.split("-")?.[0]?.toUpperCase()}</div>
            </label>
            <div className={styles.errorContainer}>
              <span className={styles.errorMessage}>{sellWarning}</span>
            </div>
            <label className={styles.field}>
              <div className={styles.label}>Total</div>
              <input
                className={styles.input}
                type="text"
                name="total"
                required
                disabled={true}
                defaultValue={sellTotal}
                placeholder="0.00"
              />
              <div className={styles.currency}>{slug?.split("-")?.[1]?.toUpperCase()}</div>
            </label>
            <div className={styles.estFee}>
              <div className={styles.titleFeeText}>Est. Fee(≈)</div>
              <div className={styles.titleFee}>0.00 <small>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</small></div>
            </div>
          </>
        )
      ) : (
        <>
          {orderType?.toLowerCase() === "buy" ? (
            <>
              <label className={styles.field}>
                <div className={styles.label}>Price</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  defaultValue={"MarketPrice"}
                  disabled={true}
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  value={buyAmount}
                  onChange={(e) => handleBuyAmountChange(e.target.value)}
                  placeholder="0.00"
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <div className={styles.errorContainer}>
                <span className={styles.errorMessage}>{buyWarning}</span>
              </div>
              <label className={styles.hideClass}>
                <div className={styles.label}>Total</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  disabled={true}
                  readOnly
                  placeholder="0.00"
                />
              </label>
              <div className={styles.estFee}>
                <div className={styles.titleFeeText}>Est. Fee(≈)</div>
                <div className={styles.titleFee}>0.00 <small>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</small></div>
              </div>
            </>
          ) : (
            <>
              <label className={styles.field}>
                <div className={styles.label}>Price</div>
                <input
                  className={styles.input}
                  type="text"
                  name="amount"
                  defaultValue={"MarketPrice"}
                  disabled={true}
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.currency?.toUpperCase()}</div>
              </label>
              <label className={styles.field}>
                <div className={styles.label}>Amount</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  value={sellAmount}
                  onChange={(e) => handleSellAmountChange(e.target.value)}
                  placeholder="0.00"
                />
                <div className={styles.currency}>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</div>
              </label>
              <div className={styles.errorContainer}>
                <span className={styles.errorMessage}>{sellWarning}</span>
              </div>
              <label className={styles.hideClass}>
                <div className={styles.label}>Total</div>
                <input
                  className={styles.input}
                  type="text"
                  name="total"
                  required
                  disabled={true}
                  readOnly
                />
              </label>
              <div className={styles.estFee}>
                <div className={styles.titleFeeText}>Est. Fee(≈)</div>
                <div className={styles.titleFee}>0.00 <small>{orderData?.current_market_details?.[0]?.coin?.toUpperCase()}</small></div>
              </div>
            </>
          )}
        </>
      )}
      <Link
        className={cn(classButton, styles.button)}
        to={"/sign-in"}
      >
        Login
      </Link>
    </>
  );
};

export default Action;
