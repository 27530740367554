import React, { useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styles from "./SemiPieChart.module.sass";

Chart.register(ArcElement, Tooltip, Legend);

const SemiCircularProgress = ({ value }) => {
    const chartRef = useRef(null);

    const data = {
        datasets: [
            {
                data: [20, 20, 20, 20, 20],
                backgroundColor: ["#eb2f44", "#e08906", "#edd407", "#96d702", "#43c784"],
                borderWidth: 2,
                cutout: "90%",
                circumference: 180,
                rotation: 270,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
        animation: {
            onComplete: () => {
                drawIndicatorDot();
            },
        },
    };

    const drawIndicatorDot = () => {
        const chart = chartRef.current;
        if (!chart) return;

        const ctx = chart.ctx;
        const centerX = chart.chartArea.left + chart.chartArea.width / 2;
        const centerY = chart.chartArea.bottom;
        const radius = (chart.chartArea.width / 2) * 0.9 + 4;

        const angle = (value?.fgl_latest / 100) * Math.PI;
        const dotX = centerX + radius * Math.cos(angle - Math.PI);
        const dotY = centerY + radius * Math.sin(angle - Math.PI);

        ctx.beginPath();
        ctx.arc(dotX, dotY, 4, 0, 2 * Math.PI);
        ctx.fillStyle = "white";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(dotX, dotY, 4, 2, 5 * Math.PI);
        ctx.fillStyle = "black";
        ctx.fill();
    };

    return (
        <div className={styles.semiCircularContainer}>
            <Doughnut ref={chartRef} data={data} options={options} />

            <div className={`${styles.chartValue} ${styles.top}`}>
                {value?.fgl_latest}
            </div>

            <div className={`${styles.chartValue} ${styles.bottom}`}>
                {value?.fgl_char_latest}
            </div>
        </div>

    );
};

export default SemiCircularProgress;
