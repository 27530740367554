import React, { useRef } from "react";
import Pay from "../../components/Pay";
import Main from "./Main";
import Merchant from "./Merchant";
import Releases from "./Releases";
import Steps from "./Steps";

const BitdenexPay = () => {
  const scrollToRef = useRef(null);
  return (
    <>
      <Main />
      <Pay classSection="section-bg" />
      <Steps />
      <Releases scrollToRef={scrollToRef} />
      <Merchant scrollToRef={scrollToRef} />


    </>
  );
};

export default BitdenexPay;
