import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <p>
            Do you believe that Bitdenex is not complying with the law, or that Bitdenex is involved in an act or omission which could harm the public interest? In that case, we would like to receive your input so that we can take appropriate measures.
          </p>
          <h4>
            What is a whistleblower?
          </h4>
          <p>
            A whistleblower is someone who raises the alarm within an organization by reporting a concerning situation. For a whistleblower, for example a (former) employee, the reporting could be a big step. He or she may fear that the report could negatively impact their relationship with the employer or colleagues. Concerns about dismissal, workplace bullying, or reassignment may arise.
          </p>
          <p>
            To safeguard the whistleblower's best interests, Bitdenex has set up a whistleblower procedure.
          </p>
          <h4>
            What does the policy include?
          </h4>
          <p className={styles.text}>
            &#45;
            Protection of the whistleblower
          </p>
          <p className={styles.text}>
            &#45;
            Careful investigation of the report
          </p>
          <p className={styles.text}>
            &#45;
            Clear procedures and guidelines
          </p>
          <p className={styles.text}>
            &#45;
            Safety and confidentiality
          </p>
          <p className={styles.text}>
            &#45;
            Option for external reporting
          </p>
          <h4>
            Who can file a report?
          </h4>
          <p>
            Anyone who has or had a work-related relationship with Bitdenex can file a report. This includes employees, former employees, volunteers, trainees, applicants, shareholders, directors, contractors, subcontractors, suppliers and anyone who works or has worked under the supervision of Bitdenex.
          </p>
          <h4>
            Reporting
          </h4>
          <p>
            Do you want to file a report regarding a breach or misconducted that is related to Bitdenex? Please follow this link.
          </p>
        </div>
        <div><Link className={styles.supportButton} to={"/whistleblower"}><span className={styles.icon}></span>Click here</Link></div>
      </div>
    </div>
  );
};

export default Article;