import React, { useState } from 'react'
import styles from "./Card.module.sass";
import cn from "classnames";
import { getDigitsAfterDecimal, getPostiveNegativeNum, handleImageError } from "../../../../../components/helper";
import { Link } from 'react-router-dom';

const Card = ({
    coins,
    title,
    filter,
    topGainers = [],
    topLosers = []
}) => {
    const [isTopGainers, setIsTopGainers] = useState(true);

    const gainerAndLoserChange = (value) => {
        setIsTopGainers(value);
    };

    const coinsToRender = isTopGainers ? topGainers : topLosers;

    return (
        <div className={styles.coinCard}>
            <div className={styles.coinCardLink}>
                {filter ?
                    isTopGainers ?
                        <div className={styles.coinTitle}>Top Gainers</div>
                        :
                        <div className={styles.coinTitle}>Top Losers</div>
                    :
                    <div className={styles.coinTitle}>{title}</div>
                }
                {filter && <div className={styles.filterIcon}>
                    <div className={styles.icons}>
                        <span
                            className={cn(styles.positive, {
                                [styles.active]: isTopGainers
                            })}
                            onClick={() =>
                                gainerAndLoserChange(true)
                            }>
                            &#9650;
                        </span>
                        <span
                            className={cn(styles.negative, {
                                [styles.active]: !isTopGainers
                            })}
                            onClick={() => gainerAndLoserChange(false)
                            }>
                            &#9660;
                        </span>
                    </div>
                </div>}
            </div>
            <div className={styles.coinList}>
                {
                    filter ?
                        coinsToRender?.length > 0 ?
                            coinsToRender?.map((coin, coinIndex) => (
                                <Link
                                    className={styles.coinItem}
                                    key={coinIndex}
                                    state={coin}
                                    to={`/coins/${coin?.name?.toLowerCase()}`}
                                >
                                    <div className={styles.coinInfo}>
                                        <img className={styles.coinLogo} src={coin?.icon} onError={handleImageError} alt="Coin" />
                                        <div className={styles.coinSymbol}>{coin.symbol}</div>
                                    </div>
                                    <div className={styles.coinPrice}>{getDigitsAfterDecimal(coin.currentMarketPrice, coin.priceDecimalPrecision)}</div>
                                    <div className={styles.coinChange}>
                                        {getPostiveNegativeNum(coin.dayChange) ?
                                            <div className={styles.positive}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                            :
                                            <div className={styles.negative}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                        }
                                    </div>
                                </Link>
                            ))
                            :
                            (
                                <div className={styles.noData}>No data available</div>
                            ) :
                        coins?.length > 0 ?
                            coins?.map((coin, coinIndex) => (
                                <Link
                                    className={styles.coinItem}
                                    key={coinIndex}
                                    state={coin}
                                    to={`/coins/${coin?.name?.toLowerCase()}`}
                                >
                                    <div className={styles.coinInfo}>
                                        <img className={styles.coinLogo} src={coin?.icon} onError={handleImageError} alt="Coin" />
                                        <div className={styles.coinSymbol}>{coin.symbol}</div>
                                    </div>
                                    <div className={styles.coinPrice}>{getDigitsAfterDecimal(coin.currentMarketPrice, coin.priceDecimalPrecision)}</div>
                                    <div className={styles.coinChange}>
                                        {getPostiveNegativeNum(coin.dayChange) ?
                                            <div className={styles.positive}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                            :
                                            <div className={styles.negative}>{getDigitsAfterDecimal(coin.dayChange, 2)}%</div>
                                        }
                                    </div>
                                </Link>
                            ))
                            :
                            (
                                <div className={styles.noData}>No data available</div>
                            )
                }
            </div>
        </div >
    )
}

export default Card
