import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import PDF from "./Documents/Risk_Disclosure_Policy.pdf";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <p>
            The cookie statement explains how Bitdenex uses cookies to secure and enhance its services.
          </p>
          {/* <p>
            <a className={styles.link} href={PDF} target="_blank" rel="noreferrer">View and download the Risk Disclosure.</a>
          </p> */}
          <h4>1. Introduction</h4>
          <p>When you visit www.bitdenex.com, use any related website, or use the Bitdenex app, Bitdenex may use cookies and similar technologies to collect information about you. As Bitdenex values your privacy and prioritizes the careful handling of your (personal) data, this “Cookie Statement” explains what these technologies are, why we use them, and your rights to control our use of them.</p>
          <p>We are responsible for the use of cookies on our websites and applications. To get in touch with Bitdenex regarding our use of cookies, please refer to the contact details provided at the bottom of this Cookie Statement.</p>
          <p>This Cookie Statement is part of, and incorporates certain terms defined in the User Agreement.</p>
          <h4>2. What are cookies?</h4>
          <p>Cookies are small text files that websites place on your device while you are browsing. These files are processed and stored by your web browser. A cookie allows data (which may include personal data) to be stored on your device and retrieved when needed.</p>
          <p>On their own, cookies are harmless and are commonly used to ensure the proper functioning of a website, improve its efficiency, and provide valuable insights to website owners. Additionally, cookies can typically be easily viewed and deleted.</p>
          <div className={cn("h5")}>2.1 Duration</div>
          <p>The duration for which a cookie stays on your browsing device depends on whether it is a persistent cookie or a session cookie, as explained below:</p>
          <p><span className={styles.strongText}>i. Session cookies:</span> These cookies are temporary and expire as soon as you close your browser or when your session ends.</p>
          <p><span className={styles.strongText}>ii. Persistent cookies:</span> These cookies remain on your hard drive until you delete them or your browser does, depending on the specific retention period set for the cookie (as outlined in section 3).</p>
          <div className={cn("h5")}>2.2 Source of the cookies</div>
          <p>Cookies may be placed by several parties, as explained below:</p>
          <p><span className={styles.strongText}>i. First-party cookies:</span> These cookies are placed on your device directly by the website you are visiting.</p>
          <p><span className={styles.strongText}>ii. Third-party cookies:</span> These cookies are placed on your device by a party other than the website you are visiting, such as an advertiser or an analytics system.</p>
          <p>Please note that, although we may use some of the information collected by third-party cookies, we do not control the types of information that are collected and stored by these cookies. For more details on how third parties use cookies, please visit the third party’s website.</p>
          <h4>3. What types of cookies do we currently use and why?</h4>
          <p>Bitdenex uses cookies for functional, analytical, and marketing purposes.</p>
          <p>Some cookies used for analytical or marketing purposes allow the storage and retrieval of personal data on your device. For example, cookies may be used to display advertisements and other relevant content based on your interests, conduct campaign analysis, and target specific audiences. We will request your consent before using these types of cookies.</p>
          <p>Below is a list of the cookies we currently use, categorized by type. This includes the provider of each cookie, the purposes for which we use them, their lifespan, and the legal basis for processing personal data through these cookies.</p>
          <div className={cn("h5")}>3.1 Functional cookies</div>
          <p>Some of the cookies we place are essential to ensure the proper technical functioning of our website. Other cookies are used to provide additional functionalities, such as saving or remembering your preferred settings.</p>
          <p>We process the following functional cookies:</p>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Source</div>
              <div className={styles.col}>Purpose</div>
              <div className={styles.col}>Expiration</div>
              <div className={styles.col}>Legal ground</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>__Secure-1PAPISID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>This cookie is used by Google to secure user sessions and for tracking the performance of advertisements on Google’s network. It stores information for enhancing ad targeting and authentication on Google services.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>__Secure-1PSID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>Used to store authentication information and improve the security of user accounts.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>__Secure-3PAPISID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>This cookie is used for advertising and user session management.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>__Secure-3PSID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>This cookie is used to manage user login sessions and improve security for Google accounts.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>receive-cookie-deprecation</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>This cookie could be used to track whether the user has accepted the change in cookie policies or cookie deprecation (e.g., blocking certain cookies due to privacy regulations).</div>
              <div className={styles.col}>15 months</div>
              <div className={styles.col}>Consent</div>
            </div>
          </div>

          <div className={cn("h5")}>3.2 Analytical cookies</div>
          <p>We use analytical cookies to gather information about how you use our website. The data collected by these cookies helps us assess the quality and effectiveness of our website. This information may include the most visited pages, the amount of time spent on specific pages, or any error messages you encounter while browsing.</p>
          <p>We process the following analytical cookies:</p>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Source</div>
              <div className={styles.col}>Purpose</div>
              <div className={styles.col}>Expiration</div>
              <div className={styles.col}>Legal ground</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>_ga</div>
              <div className={styles.col}>Google Analytics</div>
              <div className={styles.col}>This cookie is used to distinguish users for analytical purposes. It helps track user interactions with the website for statistical analysis. It’s critical for measuring website performance and understanding how users navigate the site.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>_ga_74V5VTT2N5</div>
              <div className={styles.col}>Google Analytics</div>
              <div className={styles.col}>This cookie is used to store a unique ID for tracking users and their behavior across sessions.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>_ga_HRCK7KCPZH</div>
              <div className={styles.col}>Google Analytics</div>
              <div className={styles.col}>This cookie is used to track user activity and session information across different visits.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
          </div>
          <div className={cn("h5")}>3.3 Marketing cookies</div>
          <p>We use various cookies for advertising, commercial, and promotional purposes. The personal data collected by these cookies are analyzed and used to develop campaigns and target audiences with the most relevant information and personalized communications on the website or app.</p>
          <p>We process the following marketing cookies:</p>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Name</div>
              <div className={styles.col}>Source</div>
              <div className={styles.col}>Purpose</div>
              <div className={styles.col}>Expiration</div>
              <div className={styles.col}>Legal ground</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>NID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>Used by Google to store user preferences (like language or region) and other personalization settings.</div>
              <div className={styles.col}>6 months</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>SAPISID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>This cookie is part of the Google authentication system and helps identify users in Google services. It may also help in serving personalized ads based on the user’s interaction with Google.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>SSID</div>
              <div className={styles.col}>Google</div>
              <div className={styles.col}>Used by Google to identify a particular user's session and for maintaining login information.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>XANDR_PANID</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>This cookie registers data on the visitor. The information is used to optimize advertisement relevance.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>anj</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>icu</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>May store information on user preferences or settings related to content display.</div>
              <div className={styles.col}>Unknown</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>uids</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>This cookie is used to track a user across multiple websites, acting as a way to identify and target them for advertising purposes.</div>
              <div className={styles.col}>Unknown</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>usersync</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>Used to synchronize users across different advertising platforms or networks. This cookie ensures that a user is recognized across various networks that share data for ad targeting.</div>
              <div className={styles.col}>1 year</div>
              <div className={styles.col}>Consent</div>
            </div>
            <div className={styles.row}>
              <div className={styles.col}>uuid2</div>
              <div className={styles.col}>HTTP Cookie</div>
              <div className={styles.col}>Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads.</div>
              <div className={styles.col}>3 months</div>
              <div className={styles.col}>Consent</div>
            </div>
          </div>
          <h4>4. Change your cookie settings</h4>
          <p>On your first visit to our website, you made a choice regarding whether or not to accept cookies. You can always change this preference through the cookie settings on our website. If you wish to disable cookies, receive a notification when a cookie is placed on your device, or delete all stored cookies, you can do so through your browser settings (usually found under "Help" or "Internet options"). Below, you will find links to the relevant instructions for your selected browser:</p>
          <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en&vid=0-1298829099063-1526993459868v" target="_blank">-Chrome</a></p>
          <p><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences" target="_blank">-Firefox</a></p>
          <p><a href="https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">-Internet Explorer</a></p>
          <p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">-Safari</a></p>
          <h4>5. Updating the Cookie Statement</h4>
          <p>If Bitdenex changes the cookies used on the website, we will update this Cookie Statement accordingly. Any updates to the Cookie Statement will take effect once the update is announced on the website or through any other official communication channel.</p>
          <h4>6. Contact and questions about this Cookie Statement?</h4>
          <p>If you have any questions or would like to learn more about Bitdenex's Cookie Statement, please email us at support@bitdenex.com or contact our Data Protection Officer directly at support@bitdenex.com. We will respond to your request as quickly as possible.</p>
        </div>
      </div>
    </div>
  );
};

export default Article;
