import React, { memo } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ }) => {

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h4", styles.title)}>
            Welcome to Bitdenex Status Page
          </h1>
          <div className={styles.text}>
            We use this page to communicate any issues with our platform, including both planned maintenance and unplanned outages. Stay updated on the current status of our services and any disruptions that may impact your experience.
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Main);
