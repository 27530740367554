import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import PDF from "./Documents/Bitdenex_Trading_Rules.pdf";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <p>
            The trading rules govern and ensure a safe and fair trading platform for all eligible Users.
          </p>
          <p>
            <a className={styles.link} href={PDF} target="_blank" rel="noreferrer">View and download the Bitdenex Trading Rules</a>
          </p>
          <p>
            These rules apply to all markets listed on the trading platform of Bitdenex. Bitdenex
            operates a central limit order book trading platform, where crypto assets can be
            exchanged for fiat or crypto assets and vice versa. This document describes the rules
            for trading on the Bitdenex Europe platform.
          </p>
          <h4>Trading engine and market mechanics</h4>
          <p>
            The Bitdenex trading engine matches Market Orders with Open Limit Orders within the
            Order Book for each market, listed crypto-asset. A User can only place orders in the
            Order Book if the User’s account has sufficient balance in the particular market.
          </p>

          <h4>Available Order Options</h4>
          <p>
            Order options available on the Bitdenex trading platform are:
          </p>

          <p>
            • Market Order: Market Orders are executed almost instantly and are matched with
            the best-priced and oldest Limit Orders in the Order Book at the time of the
            request to place an Order. Although is it a Market Order, there is no guarantee
            that a Market Order will fill at the Market Price indicated. Market Orders are filled
            with a number of different prices, and different Limit Orders based on the
            quantity of the Market Order and the quantities of the existing Limit Orders in the
            Order Book at the moment of execution.
          </p>
          <p>
            • Limit Order: Limit Orders are Orders to buy or sell at a fixed price set by the User
            itself. Limit Orders will be executed when the Market Price of the particular
            crypto-asset reaches the limit price set by the User.
          </p>
          <p>
            Bitdenex will add additional advanced order options on the trading platform in the near
            future.
          </p>

          <h4>Order Parameters</h4>
          <p>
            All Orders, either Limit or Market, have at least the following parameters:
          </p>
          <p>
            Market: Name of the crypto-asset.
          </p>
          <p>
            Side: Buy or Sell.
          </p>
          <p>
            Order Type: limit or market.
          </p>
          <p>
            Amount: The amount of the crypto-asset or base currency to be bought/sold.
          </p>
          <p>
            Price: The price for base asset, required for limit orders.
          </p>
          <h4>Trading Engine and Order Matching</h4>
          <div className={styles.titleText}>Listed Markets</div>
          <p>
            All Orders are matched by the self-developed and design Trading Engine of Bitdenex.
          </p>
          <p>
            • Market Orders are matched immediately with Limit Orders in the Order Book. Limit
            Orders are not matched immediately and are placed in the Order Book according to
            the set price.
          </p>
          <p>
            • Market Orders are matched based on price-time priority. This means that Market
            orders are matched with the Limit orders with the best available price. If several
            orders have the same price, the oldest Limit order is matched. This process
            continues until the order is filled, placed in the Order Book, or cancelled by User.
          </p>
          <p>
            • Orders may be settled nearly instantly or after a period of time.
          </p>
          <p>
            • Trading with oneself is not allowed. Users Order cannot match with each other.
          </p>
          <div className={styles.titleText}>Halted Markets</div>
          <p>
            In case a Market is halted, the following rules applies:
          </p>
          <p>
            • Orders cannot be placed, modified or cancelled.
          </p>
          <p>
            • No order matching occurs, Order Book will be stopped, and charts will not be
            available.
          </p>
          <p>
            • Bitdenex may, in its sole discretion, temporarily halt trading in a market to preserve
            market integrity and/or to conduct regular scheduled maintenance.
          </p>
          <h4>
            Market Protections
          </h4>
          <div className={styles.titleText}>Order Minimums</div>
          <p>
            All Market Orders and Limit Orders placed on the Bitdenex platform are subject to
            minimum order Amount requirements per market.
          </p>
          <div className={styles.titleText}>
            Spread Price Protection
          </div>
          <p>
            Market Orders are subject to a Spread Price Protection. Market Orders will fill at prices
            up to the Spread Protection Threshold from the mid-point price between the best bid
            and best offer on the Order Book at the time the Market Order is placed. If a Market
            Order would fill against Orders beyond the Spread Protection Threshold, the Order will
            partially fill up to the Spread Protection Threshold level and all the remaining quantity of
            the Order will be cancelled.
          </p>
          <div className={styles.titleText}>
            Trading Fee Structure
          </div>
          <p>
            The Bitdenex platform has a simple Trading Fee structure. A fixed percentage in trading
            fee for all executed Orders, applicable to all Users.
          </p>
          <div className={styles.titleText}>
            Market Integrity
          </div>
          <p>
            1. Bitdenex uses an advanced system to detect certain User’s behaviour. In case of
            proved manipulation, the User will be banned from the Bitdenex trading platform
            and User’s account will be closed.
          </p>
          <p>
            Users are prohibited from engaging in market manipulation which includes, but is
            not limited to, the following activities:
          </p>
          <p className={styles.text}>
            • Pump and dumps: artificially inflating the price of a crypto-asset through
            false and misleading positive statements, in order to sell the cheaply
            purchased crypto-asset at a higher price.
          </p>
          <p className={styles.text}>
            • Wash trading: Simultaneously buying and selling the same financial
            instruments to create false trading volume and artificially inflate market
            activity
          </p>
          <p className={styles.text}>
            • Front-running: Trading in advance of a large client order to take advantage of
            anticipated price movements resulting from the execution of that order. Front
            running involves using confidential information about pending transactions
            to profit at the expense of other market Participants.
          </p>
          <p className={styles.text}>
            • Insider trading: trading in crypto-assets based on non-public, material
            information that could affect the price of those crypto-assets.
          </p>
          <p className={styles.text}>
            • Information-based Market Abuse: spreading false or misleading information
            about crypto companies, crypto projects, crypto-assets, or market
            conditions to influence prices or trading activity. Includes disseminating
            rumours, false news, or deceptive market commentary to manipulate market
            participants perceptions.
          </p>
          <p>
            2. Bitdenex will inform the competent authority when any cases of market abuse or
            attempted market abuse occurring on or through the Bitdenex trading systems are
            identified.
          </p>
          <p>
            3. All Users must promptly report any suspicious activity to Bitdenex that can relate to
            illicit activities that undermine the integrity and fairness of crypto markets and could
            be identified as one of the market manipulations listed above.
          </p>
          <p>
            4. Bitdenex may cancel Open Orders for any reason, at its own discretion, including,
            but not limited to, the following circumstances:
          </p>
          <p className={styles.text}>
            • Orders placed by Users who, in Bitdenex's sole discretion, have engaged in
            abusive use of the platform, for example, Market Manipulation, or using the
            Bitdenex Services in a manner which unreasonably burdens the platform or
            Apps.
          </p>
          <p className={styles.text}>
            • Orders which involve an error with respect to price, quantity, or other
            parameters.
          </p>
          <p className={styles.text}>
            • If required by any applicable law or regulation.
          </p>
          <p className={styles.text}>
            • If required for technical reasons.
          </p>
          <p>
            5. All Users have full and equal real-time access to market data. Market data is made
            available through the web interfaces and the Bitdenex app.
          </p>
          <div className={styles.titleText}>
            Scheduled downtime
          </div>
          <p>
            Bitdenex may temporarily suspend trading in any certain market for maintenance or for
            other reasons, by providing a notification thought the website, trading platform or
            Bitdenex App beforehand. Bitdenex may suspend any Bitdenex Service at any time in
            case of unexpected and urgent circumstances, without prior notice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
