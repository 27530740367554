import React from "react";
import cn from "classnames";
import styles from "./Code.module.sass";

const Code = ({
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  authenticator,
  setAuthenticator,
  barcodeScreenHandler,
  codeSuccessData
}) => {

  return (
    <form className={styles.form}>
      <h3 className={cn("h4", styles.title)}>Enable the two factor authentication.</h3>
      <div className={styles.info}>
        Secure your account using 2FA.<br></br>In order to use 2FA, scan the QR code below into the Google Authenticator app on your phone, or enter the 16-digit key that appears under the QR code.
      </div>
      <div className={styles.info}>
        <img src={codeSuccessData?.data?.data?.[0]?.qr_code} alt="qrcode" />
      </div>
      <div className={styles.info}>
        <h3 className={cn("h6", styles.title)}> Secret key : {codeSuccessData?.data?.data?.[0]?.secret_key}</h3>
      </div>
      <div className={styles.info}>
        Please note down the secret key and enter the six-digit code provided by the Google Authenticator.
      </div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            name="code"
            type="text"
            maxLength={6}
            value={authenticator}
            onChange={(e) => { setAuthenticator(e.target.value) }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("code");
            }}
          />
        </div>
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("code", authenticator, "required|min:6")}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            barcodeScreenHandler(authenticator);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Continue
      </button>
    </form>
  );
};

export default Code;
