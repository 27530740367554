import React from "react";
import cn from "classnames";
import styles from "./Trade.module.sass";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { formatLargeValue, getDigitsAfterDecimal, getPostiveNegativeNum, handleImageError } from "../../../../components/helper";

const Trade = ({
  search,
  currentPage,
  sortBy,
  searchHandler,
  setSortBy,
  setCurrentPage,
  sortedMarkets,
  indexOfLastMarket,
  paginatedMarkets,
  marketsPerPage,
  pageNumbers,
  handlePageChange,
  totalPages
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.trade}>
      <form className={styles.form}>
        <input
          value={search}
          className={styles.input}
          type="text"
          onChange={searchHandler}
          name="search"
          placeholder="Search coin"
          required
        />
        <button onClick={(e) => { e.preventDefault(); }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>#</div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => setSortBy({
                field: "name",
                order: sortBy?.field === "name" && sortBy?.order === "asc" ? "desc" : "asc"
              })}
            >
              Name {sortBy?.field === "name" ? (sortBy.order === "asc" ? '↑' : '↓') : ""}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => setSortBy({
                field: "currentMarketPrice",
                order: sortBy?.field === "currentMarketPrice" && sortBy?.order === "asc" ? "desc" : "asc"
              })}
            >
              Price {sortBy?.field === "currentMarketPrice" ? (sortBy.order === "asc" ? '↑' : '↓') : ""}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => setSortBy({
                field: "dayChange",
                order: sortBy?.field === "dayChange" && sortBy?.order === "asc" ? "desc" : "asc"
              })}
            >
              24h % {sortBy?.field === "dayChange" ? (sortBy.order === "asc" ? '↑' : '↓') : ""}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting")}
              onClick={() => setSortBy({
                field: "weekChange",
                order: sortBy?.field === "weekChange" && sortBy?.order === "asc" ? "desc" : "asc"
              })}
            >
              7d % {sortBy?.field === "weekChange" ? (sortBy.order === "asc" ? '↑' : '↓') : ""}
            </div>
          </div>
          <div className={styles.col}>
            Marketcap
            <Icon name="coin" size="20" />
          </div>
          <div className={styles.col}>
            Volume (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>Action</div>
        </div>

        {paginatedMarkets?.map((i, ind) => {
          return (
            <Link className={styles.row} state={i} key={ind} to={`/coins/${i?.name?.toLowerCase()}`}>
              <div className={styles.col}>
                <div className={styles.line}>{(currentPage - 1) * marketsPerPage + ind + 1}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.item}>
                  <div className={styles.icon}>
                    <img src={i.icon} onError={handleImageError} alt="Coin" />
                  </div>
                  <div className={styles.details}>
                    <span className={styles.subtitle}>{i.name}</span>
                    <span className={styles.currency}>{i.symbol}</span>
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Price</div>
                {getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}
              </div>
              <div className={styles.col}>
                {getPostiveNegativeNum(i.dayChange) ?
                  <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                  :
                  <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                }
              </div>
              <div className={styles.col}>
                {getPostiveNegativeNum(i.weekChange) ?
                  <div className={styles.positive}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                  :
                  <div className={styles.negative}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                }
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Marketcap</div>
                {
                  (i?.cap !== null && i?.cap !== undefined && i?.cap !== "" && i?.cap !== 0 && i?.cap !== "0") ?
                    formatLargeValue(i?.cap)
                    :
                    (i?.self_reported_market_cap !== null && i?.self_reported_market_cap !== undefined && i?.self_reported_market_cap !== "" && i?.self_reported_market_cap !== 0 && i?.self_reported_market_cap !== "0") ?
                      <div className={styles.slippage}>
                        <span className={styles.tooltip}>
                          {formatLargeValue(i?.self_reported_market_cap)}
                          <span className={styles.tooltiptext}>The market capitalization for this project has not been verified. The value displayed is self-reported by the project.
                          </span> <span className={styles.tradeIcon}>⚠️</span>
                        </span>
                      </div>
                      :
                      "N/A"
                }
              </div>
              <div className={styles.col}>
                <div className={styles.label}>Volume (24h)</div>
                {(i.volume !== null && i.volume !== undefined && i.volume !== "") ? i.volume : "N/A"}
              </div>
              <div className={styles.col}>
                <div className={cn("button-small", styles.button)} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/sign-in");
                }}>
                  Login
                </div>
              </div>
            </Link>
          )
        })}
      </div>
      <div className={styles.pagination}>
        <button
          className={cn(styles.paginationButton, { [styles.disabled]: currentPage === 1 })}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {pageNumbers.map((number) => (
          <button
            key={number}
            className={cn(styles.paginationButton, {
              [styles.active]: currentPage === number,
            })}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </button>
        ))}

        <button
          className={cn(styles.paginationButton, { [styles.disabled]: currentPage === totalPages })}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Trade;
