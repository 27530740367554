import requestHandler from '../httpClient';
import {
    CMC_DATA_REQUEST,
    CMC_DATA_SUCCESS,
    CMC_DATA_FAILED,
} from './actionType';

const cmcDataRequest = () => {
    return {
        type: CMC_DATA_REQUEST
    };
};

const cmcDataSuccess = (payload) => {
    return {
        type: CMC_DATA_SUCCESS,
        payload: payload
    };
};

const cmcDataFailed = (error) => {
    return {
        type: CMC_DATA_FAILED,
        error: error,
    }
};

const cmcDataCreator = () => {
    return async (dispatch) => {
        dispatch(cmcDataRequest());
        try {
            const payload = await requestHandler('getCmcDataGlobal', 'post');
            dispatch(cmcDataSuccess(payload));
        }
        catch (error) {
            dispatch(cmcDataFailed(error));
        };
    };
};

export {
    cmcDataRequest,
    cmcDataSuccess,
    cmcDataFailed,
    cmcDataCreator
};