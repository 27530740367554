import React from "react";
import Main from "./Main";
import Article from "./Article";

const TermsAndConditions = () => {
  return (
    <>
      {/* <Main />
      <Article /> */}
    </>
  );
};

export default TermsAndConditions;
