import React, { memo } from "react";
import cn from "classnames";
import styles from "./Charts.module.sass";
import TVChartContainer from "../../../components/TVChartContainer";
import useDarkMode from "use-dark-mode";

const Actions = memo((
    slug,
    orderData
) => {

    const darkMode = useDarkMode(false);

    return (
        <div className={styles.charts}>
            <div className={styles.inner}>
                <div className={styles.iframe}>
                    <TVChartContainer
                        symbol={slug?.slug}
                        currentMarketDetails={orderData}
                        theme={darkMode.value ? 'dark' : 'light'}
                    />

                </div>
            </div>
        </div>
    );
});

export default Actions;
