import React from "react";
import cn from "classnames";
import styles from "./Scan.module.sass";
import Icon from "../../../components/Icon";
import Checkbox from "../../../components/Checkbox";

const Scan = ({
  setCount,
  showQrCode,
  setShowQrCode,
  setSessionId,
  getQrCodeHandler,
  qrCodeLogin,
  qrCodeData,
  goPrev,
}) => {

  return (
    <div className={styles.login}>
      <div className={styles.top}>
        <h4 className={cn("h4", styles.title)}>Sign in to Bitdenex</h4>
        <div className={styles.info}>
          Please ensure you are visiting the correct url.
        </div>
        <div className={styles.correct}>
          <Icon name="lock" size="24" />
          <div className={styles.url}>
            https://<span>bitdenex.com/sign-in</span>
          </div>
        </div>
      </div>
      <form className={styles.form} onSubmit={(e) => { e.preventDefault() }}>
        <div className={styles.checkboxContainer}>
          <Checkbox
            value={qrCodeLogin}
            content="Log in with Email"
            onChange={() => {
              goPrev();
              setCount(0);
            }}
          />
        </div>
        <img className={showQrCode ? styles.image : styles.hideBarCode} src={qrCodeData} alt="icon" />
        <div className={styles.boxContainer}>
          <div className={styles.textClass}>
            <strong>Step 1.</strong> Download the latest <a href="https://bitdenex.com/bitdenex-app" target="_blank" rel="noreferrer" className={styles.linkText}>Bitdenex app</a>, or update the Bitdenex app with the latest version.
          </div>
          <div className={styles.textClass}>
            <strong>Step 2.</strong> Log in with your Bitdenex login credentials, scan this QR-code and confirm your login.
          </div>
        </div>

        {!showQrCode &&
          <>
            <div className={styles.expiredText}>QR code expired</div>
            <button className={cn("button-small", styles.button)}
              onClick={() => {
                setShowQrCode(true);
                setSessionId("");
                setCount(0);
                getQrCodeHandler();
              }}>Refresh QR code</button>
          </>}
      </form>
    </div>
  );
};

export default Scan;
