import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import PDF from "./Documents/Risk_Disclosure_Policy.pdf";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <p>
            This risk disclosure policy outlines various risks associated with trading crypto-assets.
          </p>
          <p>
            <a className={styles.link} href={PDF} target="_blank" rel="noreferrer">View and download the Risk Disclosure.</a>
          </p>
          <p>
            This risk disclosure policy is part of the Terms of Service, the Bitdenex User Agreement.
            The definitions used but not defined herein are defined in the User Agreement.
            User uses the Bitdenex Services entirely at their own risk. By accepting the Terms of
            Services, including the User Agreement, User also accepts and consents to the risks as
            included in this Risk Disclosure Policy. This Risk Disclosure Policy includes some of the
            most principal risks of using the Services, but it cannot and does not include all risks
            involved in using the Services.
          </p>
          <p>
            Trading with crypto-assets has its risks and through it, you can lose all or part of your
            investment. Always do your own research and choose whether to invest and how to
            invest yourself. Crypto-assets can be very volatile and the past performance is not
            indicative of future results.
          </p>

          <h4>Specific risks include:</h4>
          <p>
            • Counterparty Risk: Transactions in crypto-asset markets carry exposure to
            counterparties, such as exchanges, brokers, and other trading entities. There is a
            risk that counterparties may fail to fulfill their obligations, encounter financial
            difficulties, or face regulatory actions, potentially resulting in loss of assets or
            interrupted trading operations.
          </p>
          <p>
            • Market Liquidity and Depth: Large size trades may encounter liquidity
            challenges, especially in volatile or less liquid crypto markets. Sudden liquidity
            shortages or market shifts can lead to unfavorable pricing or execution delays,
            impacting trade efficiency.
          </p>

          <p>
            • Regulatory and Compliance Risk: Regulatory environments for crypto-assets
            vary significantly across jurisdictions and are subject to change. New regulations
            or changes to existing laws may affect market access, compliance costs, and
            reporting obligations. This evolving landscape can influence trading strategies
            and asset classifications.
          </p>
          <p>
            • Technology and Infrastructure Dependence: Reliable access to advanced
            technology is essential for trade execution and monitoring. Trading platforms,
            blockchain networks, and other infrastructure components may be vulnerable to
            outages, technical failures, or latency, which could delay transaction processing
            and affect operational reliability.
          </p>
          <p>
            • Price risk: Crypto-assets are highly volatile, with prices subject to sudden and
            significant fluctuations. Factors such as market sentiment, supply and demand
            dynamics, macroeconomic conditions and regulatory developments can drive
            rapid changes in crypto-asset prices. This volatility can lead to substantial
            financial losses, particularly for investors who are not familiar with the inherent
            risks of crypto-asset markets. Price risks are exacerbated during periods of
            market uncertainty, low liquidity, or high leverage, where small changes in
            market conditions can lead to larger price implications. Investors in cryptoassets may lose some or all of their original investment.
          </p>

          <h4>Volatility</h4>
          <p>
            Crypto-assets has a volatile nature and a User can incur a substantial and even a full
            loss of Funds by buying and trading crypto- assets. Before trading crypto-assets, a User
            should define its User’s risk tolerance and its financial position, and should not trade
            with Funds which cannot be lost in its entirety.
          </p>
          <p>
            User should always take into account that any execution price of a Market Order may
            differ significantly from the indicated price. Due to the (high) volatile nature of cryptoassets, this may change in the price between the time of the Order being initiated by
            User and the execution of the Order (i.e. 'slippage'). Execution of Orders during periods
            of high volume, illiquidity, fast movement or volatility, can be affected and may be
            executed at a different rate than indicated via the Services at the time of Users Order.
            User should be aware of the involved high risk while trading and placing Order for
            crypto-assets during these high volatility periods.
          </p>
          <p>
            Each market or Orderbook of a particular crypto-asset has its own degrees of liquidity.
            Many crypto-assets are having enough liquidity while others may not have enough
            liquidity. This means that not many User are willing to trade in that particular cryptoasset, which may cause low or not enough liquidity in the market. In case of low
            liquidity in a certain market, may have potential increased risk of loss because they can
            experience high volatility of prices and in such markets market participants may find it
            impossible to liquidate market positions except at very unfavorable prices. There is no
            guarantee that the markets for any crypto-asset allow you to establish or liquidate
            positions at favorable prices or will be even active and liquid when desired.
          </p>
          <h4>Account Security</h4>
          <p>
            Crypto Asset Service Providers and their clients’ accounts are targeted frequently by
            hackers and individuals seeking unauthorized access to User Funds, which may lead to
            User Funds being irretrievably lost. It is User’s sole responsibility to safeguard its account, with a strong password and activates two factor authentication ("2FA"). The
            2FA is required for Users account at Bitdenex.
          </p>
          <h4>Human Errors</h4>
          <p>
            It is User’s sole responsibility to ensure any transactions are performed to the correct
            crypto wallets while transferring and depositing crypto-assets. Due to the nature of the
            protocols underlying Services, and the blockchains, transactions cannot be reverted in
            any situation.
          </p>
          <h4>Functioning of the Platform</h4>
          <p>
            The trading platform and/or the trading app, may be degraded during periods of high
            volume and high volatility, which may result in limitations on access to User’s Account
            and any of the Services, including the inability to initiate or complete transactions. This
            may also lead to support response time delays.
          </p>
          <h4>Information errors</h4>
          <p>
            All information, data, (marketing) materials provided on the Website, blog website, the
            Trading Platform or otherwise by Bitdenex are purely for informational purposes and
            may change without notice. In case of any information may be misleading, incomplete
            or erroneous, User can contact the Customer support team of Bitdenex to verify its
            legitimacy. User should always take responsibility for assessing the relevance,
            accuracy, adequacy, and reliability of any information provided and double check with
            other relevant information sources.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;
