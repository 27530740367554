import { Navigate, Route } from "react-router";
import requestHandler from "../actions/httpClient";
import { addNotification } from "./Notification";

const getPostiveNegativeNum = (value) => {
    if (value >= 0) {
        return true;
    } else {
        return false;
    };
};

const getDigitsAfterDecimal = (price, digits) => {
    return parseFloat(Math.trunc(price * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits);
};


const twoDigitsAfterDecimal = (price, coin) => {
    switch (coin?.toLowerCase()) {
        case 'eur':
        case 'usdt':
            return parseFloat(Math.trunc(price * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2);
        case 'btc':
        case 'eth':
        case 'ltc':
            return Math.trunc(price * Math.pow(10, 8)) / Math.pow(10, 8);
        case 'trx':
        case 'xrp':
            return Math.trunc(price * Math.pow(10, 5)) / Math.pow(10, 5);
        default:
            return Math.trunc(price * Math.pow(10, 4)) / Math.pow(10, 4);
    }
}

const toLocaleStringDateFormat = (value) => {
    return new Date(value).toLocaleString();
};

const ProtectedRoutes = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('signature');
  
    return isAuthenticated ? children : <Navigate to={"/sign-in"} />;
  };


const resendOtp = async (email, type) => {

    try {
        const data = {
            'email': email,
            'type': type,
        }



        const payload = await requestHandler('gen_otp', 'post', data, 'jwt_token', "file")
        if (payload.status === 201) {
            addNotification({
                title: 'Success',
                message: "OTP resend successfully",
                type: 'success'
            });

        }

        return payload;
    } catch (error) {

        
        return error

    }


}

const handleImageError = (e) => {
    e.target.src = "/images/content/dummyCoinIcon.png";
};

const formatFeeHandler = (amount, decimalPrecision) => {
    let feeCal = amount * (process.env.REACT_APP_MAKER_FEE / 100);
    let formattedFee = getDigitsAfterDecimal(feeCal, 8);
    formattedFee = formattedFee
      .toString()
      .replace(/(\.\d*?)0+$/,"$1")
      .replace(/\.$/, "");
    return formattedFee;
  };

function formatLargeValue(value) {
  try {
    if (value === undefined || value === null) return "";
    let num = value?.toString().trim();
    num = parseFloat(num?.replace(/,/g, ""));
    let suffix = "";
    if (num >= 1e12) {
      num /= 1e12;
      suffix = "T";
    } else if (num >= 1e9) {
      num /= 1e9;
      suffix = "B";
    } else if (num >= 1e6) {
      num /= 1e6;
      suffix = "M";
    }
    return num.toFixed(2) + suffix;
  } catch (error) {
    return "";
  }
}

export {
    getPostiveNegativeNum,
    getDigitsAfterDecimal,
    twoDigitsAfterDecimal,
    toLocaleStringDateFormat,
    ProtectedRoutes,
    resendOtp,
    handleImageError,
    formatFeeHandler,
    formatLargeValue
};


























  // const getExchangeData = async (slug) => {
  //   const formData = {
  //     market_slug: slug,
  //     signature: localStorage.getItem("signature")
  //   }

  //   try {
  //     const payload = await requestHandler('getOpenOrders', 'post', formData, 'jwt_token')

  //     { ordeerActionResponse.length==0 && setBuy(payload.data.data.buy) }
  //     { ordeerActionResponse.length==0 && setSell(payload.data.data.sell) }
      
  //     setMarketTrades(payload?.data?.data?.market_trades)
  //     setOpenOrder(payload?.data?.data?.open_order)
  //     setCurrentMarketDetails(payload?.data?.data?.current_market_details)
  //     setExchangeDta(payload)
  //   } catch (error) {
  //    }
  // }

  // const exchangeUpdateSell = (data) => {
  //   setSell(data)
  // }

  // const exchangeUpdateBuy = (data) => {
  //  setBuy(data)
  // }