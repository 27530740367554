import React, { useEffect, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";

const Market = () => {
  const [loading, setLoading] = useState();
  const [coinFee, setCoinFee] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getFeeHandler();
  }, []);

  const getFeeHandler = async () => {
    setLoading(true);
    try {
      const getFeePayload = await requestHandler("getCoinStructure", "post");
      setLoading(false);
      setCoinFee(getFeePayload?.data?.data);
    }
    catch (e) {
      setLoading(false);
    };
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
      {loading && <LoaderScreen />}
      <Main />
      <Details
        coinFee={coinFee}
        search={search}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default Market;
