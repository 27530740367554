import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import { resendOtp } from "../../../components/helper";

const Code = ({
  email,
  securityCode,
  setSecurityCode,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  codeScreenHandler
}) => {
  const [timer, setTimer] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    let interval;

    if (timer > 0 && isDisabled) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    else if (timer === 0) {
      setIsDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer, isDisabled]);

  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>Enter your security code</h3>
      <div className={styles.info}>We texted your code to {email}</div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            name="code"
            type="text"
            maxLength={6}
            value={securityCode}
            onChange={(e) => { setSecurityCode(e.target.value) }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("code");
            }}
          />
        </div>
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("code", securityCode, "required|min:6")}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            codeScreenHandler(securityCode);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Continue
      </button>
      {timer >= 1 ? <div className={cn("h6", styles.resendOtpText)}>Resend OTP in {timer} </div> : null}
      {(<button className={cn("button", styles.button)}
        disabled={isDisabled}
        onClick={(e) => {
          e.preventDefault();
          resendOtp(email, 'sign_up');
          setTimer(30);
          setIsDisabled(true);
        }}>
        Resend OTP
      </button>)}
    </form>
  );
};

export default Code;
