import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";


const items = [
  {
    id: '0',
    title: "Pay with Bitdenex Customer ID",
    currency: "Bitcoin",
    content: "To pay instantly, simply type or paste your recipient’s Customer ID.",
    category: "green",
    categoryText: "Start Trading",
    image: "/images/content/pay/pay-customer-id.jpg",
    image2x: "/images/content/pay/pay-customer-id@2x.jpg",
    url: "#",
    preUrl: "#",
  },
  {
    id: '1',
    title: "Scan to pay",
    currency: "Bitcoin",
    content: "With Bitdenex Pay, to pay someone via QR code, simply scan their Bitdenex Pay QR code with your Bitdenex app.",
    category: "green",
    categoryText: "Verify your Bitdenex Account",
    image: "/images/content/pay/pay-scan.jpg",
    image2x: "/images/content/pay/pay-scan@2x.jpg",
    url: "#",
    preUrl: "#",
  },
  {
    id: '2',
    title: "Share QR Code to receive",
    currency: "Bitcoin",
    content: "With Bitdenex Pay, to receive crypto, simply share your Bitdenex Pay QR code within your Bitdenex app.",
    category: "green",
    categoryText: "Start Trading Bitcoin",
    image: "/images/content/pay/pay-receive.jpg",
    image2x: "/images/content/pay/pay-receive@2x.jpg",
    url: "#",
    preUrl: "#",
  },
];

const Releases = ({ scrollToRef }) => {
  return (
    <div className={cn("section-bg section-mb0", styles.releases)} ref={scrollToRef} >
      <div className={cn("container", styles.container)}>
        {/* <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
          <h3 className={cn("h3", styles.title)}> Introducing <span> Bitdenex Pay </span> </h3>
          </div>
      </div> */}

        <div className={styles.list}>
          {items.map((x, index) =>
          (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
