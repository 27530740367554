import React from "react";
import cn from "classnames";
import styles from "./Code.module.sass";

const Code = ({
  verifyBarcodeHandler,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  confirmationCode,
  setConfirmationCode,
}) => {

  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>Enter your 2FA code.</h3>
      <div className={styles.info}>
        Help us to secure your account. <br></br>Please complete the verifications below
      </div>
      <div className={styles.info}>
        Enter the six-digit code provided by the Google Authenticator.
      </div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            name="code"
            type="text"
            maxLength={6}
            value={confirmationCode}
            onChange={(e) => { setConfirmationCode(e.target.value) }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("code");
            }}
          />
        </div>
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("code", confirmationCode, "required|min:6")}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            verifyBarcodeHandler(confirmationCode);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Continue
      </button>
      <div className={styles.infoText}>
        Unable to access your authentication code? <br></br>Please raise ticket at <span className={styles.correct}>support@bitdenex.com</span>
      </div>
    </form>
  );
};

export default Code;
