import React from "react";
import cn from "classnames";
import styles from "./NewPassword.module.sass";
import TextInput from "../../../components/TextInput";

const NewPassword = ({
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  togglePasswordHandler,
  passwordType,
  resetPasswordHandler,
  email
}) => {
  return (
    <form className={styles.form}>
      <h3 className={cn("h3", styles.title)}>New password</h3>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="new password"
          name="password"
          type={passwordType}
          placeholder="Password"
          view
          togglePassword={togglePasswordHandler}
          value={password}
          onChange={(e) => { setPassword(e.target.value) }}
          onBlur={() => {
            simpleValidator.current.showMessageFor("password");
          }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("password", password, "required|min:9")}
        </span>
        <TextInput
          className={styles.field}
          label="confirm password"
          name="confirm-password"
          type="password"
          placeholder="Password"
          value={confirmPassword}
          onChange={(e) => { setConfirmPassword(e.target.value) }}
          onBlur={() => {
            simpleValidator.current.showMessageFor("confirm-password");
          }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("confirm password", confirmPassword, `required|in:${password}`)}
        </span>
      </div>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            resetPasswordHandler(email, password, confirmPassword)
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >Continue</button>
    </form>
  );
};

export default NewPassword;
