import React from "react";
import cn from "classnames";
import styles from "./Trend.module.sass";
import { Link } from "react-router-dom";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../components/helper";
import TradeSkeleton from "../../../components/Skeleton/TradeSkeleton";

const Trend = ({
  loading,
  socketMarketData,
}) => {

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.line}>
          <h2 className={cn("h3", styles.title)}>Popular cryptocurrencies</h2>
          <Link className={cn("button-stroke", styles.button)} to="/market">
            View more
          </Link>
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>Name</div>
            <div className={styles.col}>Price(EUR)</div>
            <div className={styles.col}>24h %</div>
            <div className={styles.col}>7d %</div>
            <div className={styles.col}>Trade</div>
          </div>
          {loading ? <TradeSkeleton rowCount={9} colCount={6} />
            :
            socketMarketData?.[0]?.[0]?.markets
              ?.slice(0, 9)
              ?.map((x, index) => (
                <Link className={styles.row} state={x} to={`/coins/${x?.name?.toLowerCase()}`} key={index}>
                  <div className={styles.col}>{index + 1}</div>
                  <div className={styles.col}>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={x.icon} alt="Currency" />
                      </div>
                      <div className={styles.details}>
                        <span className={styles.subtitle}>{x.coin_name}</span>
                        <span className={styles.currency}>{x.symbol}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.col}>{getDigitsAfterDecimal(x.currentMarketPrice, x.priceDecimalPrecision)}</div>
                  <div className={styles.col}>
                    {getPostiveNegativeNum(x.dayChange) ?
                      <div className={styles.positive}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                      :
                      <div className={styles.negative}>{getDigitsAfterDecimal(x.dayChange, 2)}%</div>
                    }
                  </div>
                  <div className={styles.col}>
                    {getPostiveNegativeNum(x.weekChange) ?
                      <div className={styles.positive}>{getDigitsAfterDecimal(x.weekChange, 2)}%</div>
                      :
                      <div className={styles.negative}>{getDigitsAfterDecimal(x.weekChange, 2)}%</div>
                    }
                  </div>
                  <div className={styles.col}>
                    <div className={styles.col}>
                      <Link className={cn("button-stroke", styles.button)} to={"/sign-up"}>
                        Trade Now
                      </Link>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Trend;
