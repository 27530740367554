import { useState, useEffect } from "react";
import styles from "./CookieConsent.module.sass";
import { Link } from "react-router-dom";
import cn from "classnames";

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem("consent");
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem("consent", true);
        setShowBanner(false);
    };

    const closeBanner = () => {
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className={styles.cookieBanner}>
            <div className={styles.cookieContent}>
                <div className={cn("h3", styles.cookieTitle)}>This website uses cookies</div>
                <p className={styles.cookieDescription}>
                    We use cookies on our website to enhance your experience, analyze our traffic, and for marketing purposes.
                    Read our <Link to="/cookie-policy" className={styles.cookieLink}>Cookie Statement</Link> for more information.
                </p>
            </div>

            <div className={styles.cookieActions}>
                <button className={styles.cookieSettings} onClick={closeBanner}>Close</button>
                <button className={styles.cookieAccept} onClick={acceptCookies}>Accept all cookies</button>
            </div>
        </div>
    );
};

export default CookieBanner;
