import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Trade from "./Trade";

const Details = ({
  coinFee,
  search,
  handleSearch,
}) => {

  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Trade
          coinFee={coinFee}
          search={search}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default Details;