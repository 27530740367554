import React, { useState } from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import Dropdown from "../../../components/Dropdown";
import { Link } from "react-router-dom";

const navigation = ["My Open orders", "Market trades"];

const Table = ({ }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);

  return (
    <div className={styles.inner}>
      <Dropdown
        className={styles.dropdown}
        value={sorting}
        setValue={setSorting}
        options={navigation}
      />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Time</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Order ID</div>
          <div className={styles.col}>Market</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Amount</div>
          <div className={styles.col}>Total</div>
          {activeIndex === 0 &&
            <>
              <div className={styles.col}>Fee</div>
              <div className={styles.col}>Action</div>
            </>
          }
          {activeIndex === 1 &&
            <div className={styles.col}>Status</div>
          }
        </div>
      </div>
      <div className={styles.textCenter}>
        <Link to="/sign-in">Log in</Link>or<Link to="/sign-up">Sign up</Link> to start trading
      </div>
    </div>
  );
};

export default Table;
