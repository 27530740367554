import React, { useEffect, useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Item from "./Item";
import LightWeightChart from "../../../components/LightWeightChart";
import { formatLargeValue, getDigitsAfterDecimal } from "../../../components/helper";
import { Link } from "react-router-dom";

const Details = ({
  state,
  items
}) => {
  const [filteredData, setFilteredData] = useState(null);

  const isValid = (value) => {
    return value !== null && value !== undefined && value !== '' && value != 0;
  };

  useEffect(() => {
    fetch('/coinsData.json')
      .then(response => response.json())
      .then(data => {
        const initialFilteredData = data?.filter(item => item?.symbol?.toLowerCase() === state?.symbol?.toLowerCase())?.map(x => x?.about)[0];
        setFilteredData(initialFilteredData);
      })
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={cn("h5")}>{`${state?.name}(${state?.symbol})`} Price</div>
          <div className={styles.price}>{state?.regionSymbol}{state?.currentMarketPrice}</div>
          <div className={styles.historicalPrice}>
            {
              (state?.cap !== null && state?.cap !== undefined && state?.cap !== "" && state?.cap !== 0 && state?.cap !== "0") ? (
                <div className={styles.priceItem}>
                  <div className={styles.key}>Marketcap</div>
                  <div className={styles.value}>{state?.regionSymbol}{formatLargeValue(state?.cap)}</div>
                </div>
              ) : (state?.self_reported_market_cap !== null && state?.self_reported_market_cap !== undefined && state?.self_reported_market_cap !== "" && state?.self_reported_market_cap !== 0 && state?.self_reported_market_cap !== "0") ? (
                <div className={styles.priceItem}>
                  <div className={styles.key}>Marketcap</div>
                  <div className={styles.value}>
                    {state?.regionSymbol}{formatLargeValue(state?.self_reported_market_cap)}
                    <span className={styles.tooltipContainer}>
                      <span className={styles.tooltipIcon}>⚠️</span>
                      <span className={styles.tooltipText}>
                        The market capitalization for this project has not been verified. The value displayed is self-reported by the project.
                      </span>
                    </span>
                  </div>
                </div>
              ) : null
            }

            {isValid(state?.fully_diluted_market_cap) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Fully Diluted Valuation</div>
                <div className={styles.value}>{state?.regionSymbol}{state?.fully_diluted_market_cap.toLocaleString()}</div>
              </div>
            )}

            {isValid(state?.market_cap_dominance) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Market Dominance</div>
                <div className={styles.value}>{getDigitsAfterDecimal(state?.market_cap_dominance, 2)}%</div>
              </div>
            )}

            {/* {isValid(state?.volume_change_24h) && (
            <div className={styles.priceItem}>
              <div className={styles.key}>24 Hour Trading Vol</div>
              <div className={styles.value}>${state?.volume_change_24h}</div>
            </div>
          )} */}

            {isValid(state?.circulating_supply) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Circulating Supply</div>
                <div className={styles.value}>{state?.circulating_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
              </div>
            )}

            {isValid(state?.max_supply) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Max Supply</div>
                <div className={styles.value}>{state?.max_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
              </div>
            )}

            {isValid(state?.total_supply) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Total Supply</div>
                <div className={styles.value}>{state?.total_supply?.toLocaleString()}<span className={styles.symbol}> {state?.symbol}</span></div>
              </div>
            )}

            {isValid(state?.assetName) && (
              <div className={styles.priceItem}>
                <div className={styles.key}>Assest Type</div>
                <div className={styles.value}>{state?.assetName}</div>
              </div>
            )}
            <div className={styles.priceItem}>
              <div className={styles.key}>ESG Reporting Publication Date</div>
              <div className={styles.value}>Feb 24, 2025</div>
            </div>
          </div>
          {
            (
              state?.github_url || state?.official_urls || state?.white_paper_urls || state?.mica_paper_urls) && (
              <div className={styles.historicalPrice}>
                <div className={styles.information}>
                  <div className={styles.header}>Info</div>
                  <div className={styles.buttonContainer}>
                    {state?.official_urls && (
                      <a href={state.official_urls} target="_blank" rel="noopener noreferrer">
                        <button className={styles.button}>
                          <span>Website</span>
                        </button>
                      </a>
                    )}
                    {state?.white_paper_urls && (
                      <a href={state.white_paper_urls} target="_blank" rel="noopener noreferrer">
                        <button className={styles.button}>
                          <span>Whitepaper</span>
                        </button>
                      </a>
                    )}
                    {state?.mica_paper_urls && (
                      <a href={state.mica_paper_urls} target="_blank" rel="noopener noreferrer">
                        <button className={styles.button}>
                          <span>MiCA Whitepaper</span>
                        </button>
                      </a>
                    )}
                    {state?.esg_report_url !== null && state?.esg_report_url !== undefined ? (
                      <a href={state.esg_report_url} target="_blank" rel="noopener noreferrer">
                        <button className={styles.button}>
                          <span>ESG reporting</span>
                        </button>
                      </a>
                    ) :
                      <button className={styles.button}>
                        <span>ESG reporting</span>
                      </button>
                    }
                    {state?.github_url && (
                      <a href={state.github_url} target="_blank" rel="noopener noreferrer">
                        <button className={styles.button}>
                          <span>Github</span>
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          }
          {
            parseInt(process.env.REACT_APP_COIN_DETAILS_SHOW_TRADE_BUTTON) === 1 &&
            <div className={styles.historicalPrice}>
              <div className={styles.information}>
                <div className={styles.header}>Trade</div>
                <div className={styles.buttonContainer}>
                  <Link to={"/sign-up"}>
                    <button className={styles.tradeButton}>
                      <span>Buy/Sell {state?.slug}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          }

          {/* <div className={styles.historicalPrice}>
          <div className={styles.information}>BTC Historical Price</div>
          <div className={styles.priceItem}>
            <div className={styles.key}>24h Range</div>
            <div className={styles.value}>${state?.cap}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>7d Range</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>All-Time High</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
          <div className={styles.priceItem}>
            <div className={styles.key}>All-Time Low</div>
            <div className={styles.value}>${state?.volume}</div>
          </div>
        </div> */}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.chartContainer}>
            <LightWeightChart
              state={state}
            />
          </div>
          <div className={styles.timeIntervalContainer}>
            <table className={styles.timeIntervalTable}>
              <thead>
                <tr className={styles.tableHeader}>
                  <th className={styles.headerCell}>1h</th>
                  <th className={styles.headerCell}>24h</th>
                  <th className={styles.headerCell}>7d</th>
                  <th className={styles.headerCell}>30d</th>
                  <th className={styles.headerCell}>60d</th>
                  <th className={styles.headerCell}>90d</th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                <tr className={styles.tableRow}>
                  <td className={styles.tableCell}>
                    {state?.percent_change_1h > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_1h, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_1h, 2)}%</span>
                    }
                  </td>
                  <td className={styles.tableCell}>
                    {state?.dayChange > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.dayChange, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.dayChange, 2)}%</span>
                    }
                  </td>
                  <td className={styles.tableCell}>
                    {state?.weekChange > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.weekChange, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.weekChange, 2)}%</span>
                    }
                  </td>
                  <td className={styles.tableCell}>
                    {state?.percent_change_30d > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_30d, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_30d, 2)}%</span>
                    }
                  </td>
                  <td className={styles.tableCell}>
                    {state?.percent_change_60d > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_60d, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_60d, 2)}%</span>
                    }
                  </td>
                  <td className={styles.tableCell}>
                    {state?.percent_change_90d > 0 ?
                      <span className={styles.geckoUp}>{getDigitsAfterDecimal(state?.percent_change_90d, 2)}%</span>
                      :
                      <span className={styles.geckoDown}>{getDigitsAfterDecimal(state?.percent_change_90d, 2)}%</span>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {
            filteredData &&
            <>
              <div className={cn("h5", styles.infoHeading)}>
                What is {state?.name} ({state?.symbol})?
              </div>
              <div className={styles.aboutLine}>
                {filteredData?.split('\n')?.map((line, index) => {
                  return <div key={index}>{line}</div>
                })}
              </div>
            </>
          }

          {/* <div className={cn("h4", styles.questions)}>People Also Ask: Other Questions About Bitcoin</div>
        <div className={styles.about}>
          {items.map((x, index) => (
            <Item
              className={styles.item}
              item={x}
              index={index}
              key={index}
            />
          ))}
        </div> */}
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <p>The offered data is solely intended for informational reasons. This information is provided as is, with no warranties or assurances, and some of this is sourced from CoinMarketCap. Be mindful of the risks involved.</p>
        </div>
      </div>
    </>
  );
};

export default Details;
