import React from "react";
import cn from "classnames";
import styles from "./Code.module.sass";
import Icon from "../../../components/Icon";

const Code = ({
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  signinData,
  verifyBarcodeHandler,
  barcodeSecurityCode,
  setBarcodeSecurityCode,
}) => {

  return (
    <form className={styles.form}>
      <h3 className={cn("h4", styles.title)}>Enable the two factor authentication.</h3>
      <div className={styles.info}>
        Help us to secure your account.<br></br>In order to use 2FA, scan the QR code below into the Google Authenticator app on your phone, or enter the 16-digit key that appears under the QR code.
      </div>
      <div className={styles.info}>
        <img src={signinData?.data?.data?.[0]?.qr_code} alt="icon" />
      </div>
      <div className={styles.info} >
        <h3 className={cn("h6", styles.title)}> Secret key : {signinData?.data?.data?.[0]?.secret_key} <span title="Please save the secret key as it will be used to recover your 2FA in case of loss"><Icon name="calendar" size="24" /></span></h3>
      </div>
      <div className={styles.info}>
        Please note down the secret key and enter the six-digit code provided by the Google Authenticator.
      </div>
      <div className={styles.code}>
        <div className={styles.number}>
          <input
            name="code"
            type="text"
            maxLength={6}
            value={barcodeSecurityCode}
            onChange={(e) => { setBarcodeSecurityCode(e.target.value) }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("code");
            }}
          />
        </div>
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("code", barcodeSecurityCode, "required|min:6")}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            verifyBarcodeHandler(barcodeSecurityCode);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Continue
      </button>
    </form>
  );
};

export default Code;
