import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "./Main";
import Balance from "./Balance";
import Currency from "./Currency";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import { useMediaQuery } from "react-responsive";
import requestHandler from "../../actions/httpClient";
import { useDispatch, useSelector } from "react-redux";
import { socketDataReceived } from "../../actions/markets/index";
import { useParams } from "react-router";
import LoaderScreen from "../../components/LoaderScreen";
import { io } from "socket.io-client";

const navigation = ["Chart", "Order books", "Trades"];
const OrderTypeNavigation = ["Limit", "Market"];

const Exchange = () => {
    const { slug } = useParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const { socketMarketData } = useSelector((state) => state.markets);
    const dispatch = useDispatch();
    const [selectedMarket, setSelectedMarket] = useState([]);
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const [marketSearch, setMarketSearch] = useState("");
    const [marketActiveIndex, setMarketActiveIndex] = useState(1);
    const [activeColumn, setActiveColumn] = useState({ key: "", value: false });
    const [buyOrdersData, setBuyOrdersData] = useState([]);
    const [sellOrdersData, setSellOrdersData] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [currentMarketPrice, setCurrentMarketPrice] = useState("");
    const [previousPrice, setPreviousPrice] = useState(null);
    const [priceTrend, setPriceTrend] = useState(null);
    const priceColor = priceTrend === 'up' ? 'top' : priceTrend === 'down' ? 'bottom' : "top";
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const regions = [...new Set(socketMarketData?.flat()?.map((item) => item?.region_name))];

    const currencies = socketMarketData?.flat()?.filter((item) => item?.region_name === selectedRegion);

    const selectedCurrencyData = currencies?.find((item) => item?.currency === selectedCurrency);

    const regionChangeHandler = (value) => {
        setSelectedRegion(value);
        setSelectedCurrency("");
    };

    const currencyChangeHandler = (value) => {
        setSelectedCurrency(value?.currency);
    };

    useEffect(() => {
        if (currencies.length) {
            setSelectedCurrency(currencies[0]?.currency);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (!selectedRegion && regions?.length) {
            setSelectedRegion(regions[0]);
        }
    }, [regions, selectedRegion]);

    // useEffect for sockets data (Market socket, Order Book)
    useEffect(() => {
        if (slug) {
            let timeoutId = null;
            const socket = io(process.env.REACT_APP_SOCKET_URL, {
                autoConnect: false,
                transports: ['websocket']
            });
            let marketSocket = "new_all_markets_web"
            let orderBook = `new_orderBook_${slug.toLowerCase()}`
            let orderBookPrice = `new_orderBookPrice_${slug.toLowerCase()}`
            const marketData = "all_markets";
            socket.connect();

            socket.on('connect', () => {
                socket.emit("markets", marketData);
                socket.emit("subscribe", slug?.toLowerCase());
            });

            socket.on(marketSocket, (data) => {
                dispatch(socketDataReceived(data));
            });

            socket.on(orderBook, function (order) {
                let buyMaxVol = calculateMaxVolumeOfOrderBook(order, "buy");
                let sellMaxVol = calculateMaxVolumeOfOrderBook(order, "sell");
                setBuyOrdersData(createOrderbook(order?.buy, buyMaxVol));
                setSellOrdersData(createOrderbook(order?.sell, sellMaxVol));
            });

            socket.on(orderBookPrice, function (order) {
                const newPrice = parseFloat(order?.currentMarketPrice);

                if (parseFloat(previousPrice) !== null) {
                    if (parseFloat(newPrice) >= parseFloat(previousPrice)) {
                        setPriceTrend('up');
                    } else if (parseFloat(newPrice) < parseFloat(previousPrice)) {
                        setPriceTrend('down');
                    }
                }

                setPreviousPrice(newPrice);
                setCurrentMarketPrice(newPrice);
                timeoutId = setTimeout(() => {
                    socket.emit("markets", marketData);
                    socket.emit("subscribe", slug?.toLowerCase());
                }, parseInt(process.env.REACT_APP_ORDERBOOK_EMIT_TIME_INERTVAL));
            });

            return (() => {
                clearTimeout(timeoutId);
                socket.disconnect();
            });
        }
    }, [slug]);

    // useEffect to call the openOrder function
    useEffect(() => {
        getOpenOrders(slug);
    }, [slug]);

    // useEffect to set the selected market data
    useEffect(() => {
        if (slug && selectedCurrencyData) {
            const marketDetails = selectedCurrencyData?.markets?.find((market) => market?.slug === slug);
            if (marketDetails && marketDetails?.slug !== selectedMarket?.slug) {
                setSelectedMarket(marketDetails);
            }
        }
    }, [slug, selectedCurrencyData]);

    // getOpenOrder api
    const getOpenOrders = async (slug) => {
        setLoading(true);
        let data = {
            market_slug: slug,
        };
        try {
            const getOpenOrderPayload = await requestHandler("getOpenOrdersWithoutSignature", "post", data, "jwt_token");
            setLoading(false);
            setOrderData(getOpenOrderPayload?.data?.data);
        }
        catch (e) {
            setLoading(false);
        };
    };

    // callback to render the charts component for once and if the slug changes
    const renderCharts = useCallback(() => {
        return <Charts
            slug={slug}
            orderData={orderData}
        />
    }, [slug]);

    const createOrderbook = (orders, maxVol) => {
        let createOrders = [];
        let tempData = [];
        for (let order of orders) {
            if (maxVol) {
                let newNormalizedVolume = Number(order?.remfiat) / Number(maxVol);
                order.normalizedVolume = newNormalizedVolume;
            }
            if (tempData.indexOf(parseFloat(order.fiatPrice)) < 0) {
                createOrders.push(order)
                tempData.push(parseFloat(order.fiatPrice));
            } else {
                let index = tempData.indexOf(parseFloat(order.fiatPrice));
                createOrders[index].remcrypto = parseFloat(order.remcrypto) + parseFloat(createOrders[index].remcrypto);
                createOrders[index].remfiat = parseFloat(order.remfiat) + parseFloat(createOrders[index].remfiat);
            }
        }
        return createOrders;
    };

    const handleSort = (columnName, filteredData) => {
        const sortingType = order === "ASC" ? "DSC" : "ASC";
        setOrder(sortingType);
        setActiveColumn({ key: columnName, value: true });

        // let newSortedData = filteredData
        // if (columnName === '') return newSortedData
        // if (columnName === "name") {
        //     const sorted =
        //         order && order === "ASC"
        //             ? filteredData.sort((a, b) =>
        //                 a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : -1
        //             )
        //             : filteredData.sort((a, b) =>
        //                 a["name"].toLowerCase() < b["name"].toLowerCase() ? 1 : -1
        //             );
        //     newSortedData = sorted;
        // }
        // else if (columnName === "price") {
        //     const sorted =
        //         order && order === "ASC"
        //             ? filteredData.sort((a, b) =>
        //                 a["currentMarketPrice"] > b["currentMarketPrice"] ? 1 : -1
        //             )
        //             : filteredData.sort((a, b) =>
        //                 a["currentMarketPrice"] < b["currentMarketPrice"] ? 1 : -1
        //             );
        //     newSortedData = sorted;
        // }
        // else if (columnName === "dayChange") {
        //     const sorted =
        //         order && order === "ASC"
        //             ? filteredData.sort((a, b) =>
        //                 a["dayChange"] > b["dayChange"] ? 1 : -1
        //             )
        //             : filteredData.sort((a, b) =>
        //                 a["dayChange"] < b["dayChange"] ? 1 : -1
        //             );
        //     newSortedData = sorted;
        // }
        // setFilteredMarkets(newSortedData);
    };

    function calculateMaxVolumeOfOrderBook(orderBook, orderBookType) {
        try {
            const { buy, sell } = orderBook;
            if (orderBookType?.toLowerCase() === "buy") {
                const bidVolumes = buy.map(bid => bid.remfiat);
                const maxBidVolume = Math.max(...bidVolumes);
                return maxBidVolume;
            } else {
                const askVolumes = sell.map(ask => ask.remfiat);
                const maxAskVolume = Math.max(...askVolumes);
                return maxAskVolume;
            }
        } catch (error) {
            return 0;
        };
    };

    return (
        <>
            <div className={styles.exchange}>
                {loading && <LoaderScreen />}
                <Main
                    selectedMarket={selectedMarket}
                    currentMarketPrice={currentMarketPrice}
                    priceColor={priceColor}
                    orderData={orderData}
                />
                <div className={styles.nav}>
                    {navigation.map((x, index) => (
                        <button
                            className={cn(styles.link, {
                                [styles.active]: index === activeIndex,
                            })}
                            onClick={() => setActiveIndex(index)}
                            key={index}
                        >
                            {x}
                        </button>
                    ))}
                </div>
                {isTablet ? (
                    <>
                        <Actions
                            OrderTypeNavigation={OrderTypeNavigation}
                            orderData={orderData}
                            slug={slug}
                            currentMarketPrice={currentMarketPrice}
                        />
                        {activeIndex === 0 && (
                            <div className={styles.box}>
                                {renderCharts()}
                            </div>
                        )}
                        {activeIndex === 1 && (
                            <div className={styles.box}>
                                <Balance
                                    buy={buyOrdersData}
                                    sell={sellOrdersData}
                                    orderData={orderData}
                                    currentMarketPrice={currentMarketPrice}
                                    priceColor={priceColor}
                                />
                            </div>
                        )}
                        {activeIndex === 2 && (
                            <div className={styles.box}>
                                <Currency
                                    marketSearch={marketSearch}
                                    setMarketSearch={setMarketSearch}
                                    setMarketActiveIndex={setMarketActiveIndex}
                                    marketActiveIndex={marketActiveIndex}
                                    filteredMarkets={filteredMarkets}
                                    handleSort={handleSort}
                                    activeColumn={activeColumn}
                                    setCurrentMarketPrice={setCurrentMarketPrice}
                                    socketMarketData={socketMarketData}
                                    order={order}
                                    regions={regions}
                                    currencies={currencies}
                                    selectedRegion={selectedRegion}
                                    regionChangeHandler={regionChangeHandler}
                                    selectedCurrency={selectedCurrency}
                                    currencyChangeHandler={currencyChangeHandler}
                                    selectedCurrencyData={selectedCurrencyData}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Balance
                                    buy={buyOrdersData}
                                    sell={sellOrdersData}
                                    orderData={orderData}
                                    currentMarketPrice={currentMarketPrice}
                                    priceColor={priceColor}
                                />
                            </div>
                            <div className={styles.col}>
                                {renderCharts()}
                                <Actions
                                    OrderTypeNavigation={OrderTypeNavigation}
                                    orderData={orderData}
                                    slug={slug}
                                    currentMarketPrice={currentMarketPrice}
                                />
                            </div>
                            <div className={styles.col}>
                                <Currency
                                    marketSearch={marketSearch}
                                    setMarketSearch={setMarketSearch}
                                    setMarketActiveIndex={setMarketActiveIndex}
                                    marketActiveIndex={marketActiveIndex}
                                    filteredMarkets={filteredMarkets}
                                    handleSort={handleSort}
                                    activeColumn={activeColumn}
                                    setCurrentMarketPrice={setCurrentMarketPrice}
                                    socketMarketData={socketMarketData}
                                    order={order}
                                    regions={regions}
                                    currencies={currencies}
                                    selectedRegion={selectedRegion}
                                    regionChangeHandler={regionChangeHandler}
                                    selectedCurrency={selectedCurrency}
                                    currencyChangeHandler={currencyChangeHandler}
                                    selectedCurrencyData={selectedCurrencyData}
                                />
                            </div>
                        </div>
                        <Table />
                    </>
                )}
            </div>
        </>
    );
};

export default Exchange;
