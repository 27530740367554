import React from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.sass";
import ScrollButton from "../../../components/ScrollButton";
import Cards from "./Cards";
import TextInput from "../../../components/TextInput";

const Main = ({
  scrollToRef,
  loading,
  socketMarketData,
  email,
  setEmail,
  getStartedHandler
}) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    getStartedHandler(email);
    if (email) {
      const encodedEmail = encodeURIComponent(email);
      navigate(`/sign-up?username=${encodedEmail}`);
    } else {
      navigate('/sign-up');
    }
  };

  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h2", styles.title)}>
            Buy & sell <br></br><span>{process.env.REACT_APP_MARKETS_COUNT}</span> Crypto Assets in minutes
          </h1>
          <div className={styles.text}>
            Trade Bitcoin, Ethereum, Cardano, XRP and <span>{process.env.REACT_APP_MARKETS_COUNT} top </span>altcoins at the fast growing Crypto Asset Exchange.
          </div>
          <div className={styles.emailOptions}>
            <div className={styles.email}>
              <TextInput
                placeholder="Email"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </div>
            <div className={styles.link}>
              <button className={cn("button", styles.button)} onClick={handleSubmit}>
                Start Trading
              </button>
            </div>
          </div>
          <ScrollButton
            onScroll={() =>
              scrollToRef.current.scrollIntoView({ behavior: "smooth" })
            }
            className={styles.scroll}
          />
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/cards@2x.png"
            src="/images/content/cards.png"
            alt="Cards"
          />
        </div>
        <Cards
          className={styles.cards}
          loading={loading}
          socketMarketData={socketMarketData}
        />
      </div>
    </div>
  );
};

export default Main;
