import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Form.module.sass";
import TextInput from "../../../components/TextInput";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";

const Form = ({
  onScan,
  email,
  setEmail,
  password,
  setPassword,
  captcha,
  setCaptcha,
  passwordType,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  togglePasswordHandler,
  captchaData,
  loading,
  loginHandler
}) => {
  const base64data = btoa(unescape(encodeURIComponent(captchaData?.buffer)));

  return (
    <>
      <div className={styles.top}>
        <h3 className={cn("h4", styles.title)}>Sign in to Bitdenex</h3>
        <div className={styles.info}>
          Please ensure you are visiting the correct url.
        </div>
        <div className={styles.correct}>
          <Icon name="lock" size="24" />
          <div className={styles.url}>
            https://<span>bitdenex.com/sign-in</span>
          </div>
        </div>
      </div>
      <form className={styles.form}>
        <div className={styles.container}>
          <TextInput
            className={styles.field}
            label="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            placeholder="Please enter email address"
            required
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("email", email, "required")}
        </span>
        <div className={styles.container}>
          <TextInput
            className={styles.field}
            label="Password"
            name="password"
            type={passwordType}
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
            placeholder="Please enter password"
            required
            view
            togglePassword={togglePasswordHandler}
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("password", password, "required|min:9")}
        </span>
        {!loading ? <img className={styles.captcha} src={`data:image/svg+xml;base64,${base64data}`} alt="captcha" /> : <Loader className={styles.loader} />}
        <div className={styles.container}>
          <TextInput
            className={styles.field}
            name="captcha"
            type="text"
            placeholder="Please enter captcha"
            required
            value={captcha}
            onChange={(e) => { setCaptcha(e.target.value) }}
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("captcha", captcha, "required")}
        </span>
        <div className={styles.foot}>
          <button
            type="button"
            className={styles.scan}
            onClick={(e) => {
              e.preventDefault();
              onScan();
            }}>
            Scan to login
          </button>
          <Link className={styles.link} to="/forgot-password">
            Forgot password?
          </Link>
        </div>
        <button className={cn("button", styles.button)}
          onClick={(e) => {
            e.preventDefault();
            if (simpleValidator.current.allValid()) {
              loginHandler(email, password, captcha, captchaData?.captchaId);
            }
            else {
              simpleValidator.current.showMessages();
              setForceUpdate(forceUpdate + 1);
            }
          }}>
          Login
        </button>
      </form>
    </>
  );
};

export default Form;
