import React, { memo } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import { formatLargeValue } from "../../../components/helper";

const Main = ({
  usdPrice,
  cmcData,
  selectedRegion
}) => {

  let marketCap = (usdPrice && cmcData?.total_market_cap) ? formatLargeValue(parseFloat(usdPrice) * parseFloat(cmcData?.total_market_cap)) : "";

  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h2", styles.title)}>
            Today’s Cryptocurrency prices
          </h1>
          <div className={styles.text}>
            The global crypto market cap is <strong>{selectedRegion?.toLowerCase().includes("eu") ? `€${marketCap}` : `$${formatLargeValue(cmcData?.total_market_cap)}`}</strong>
          </div>
          <div className={styles.text}>
            <strong><span>{process.env.REACT_APP_MARKETS_COUNT}</span> </strong>Crypto Assets available.
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/images/content/market-pic@2x.png 2x"
            src="/images/content/market-pic.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Main);
