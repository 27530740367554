import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Cards from "./Cards";
import Trade from "./Trade";
import Categories from "./Categories";

const Details = ({
  search,
  selectedCurrency,
  selectedCategory,
  categories,
  currentPage,
  sortBy,
  recentMarkets,
  topGainers,
  topLosers,
  topVolume,
  cmcData,
  usdPrice,
  regionChangeHandler,
  selectedRegion,
  regions,
  currencies,
  currencyChangeHandler,
  categoryChangeHandler,
  searchHandler,
  setSortBy,
  setCurrentPage,
  sortedMarkets,
  indexOfLastMarket,
  paginatedMarkets,
  marketsPerPage,
  recentAdded,
  pageNumbers,
  handlePageChange,
  totalPages
}) => {

  return (
    <div className={cn("section", styles.details)}>
      <div className={styles.container}>
        <Cards
          recentMarkets={recentMarkets}
          topGainers={topGainers}
          topLosers={topLosers}
          topVolume={topVolume}
          cmcData={cmcData}
          selectedCurrency={selectedCurrency}
          usdPrice={usdPrice}
          recentAdded={recentAdded}
          selectedRegion={selectedRegion}
        />
        <Panel
          selectedCurrency={selectedCurrency}
          regionChangeHandler={regionChangeHandler}
          selectedRegion={selectedRegion}
          regions={regions}
          currencies={currencies}
          currencyChangeHandler={currencyChangeHandler}
        />
        {/* <Categories
          selectedCategory={selectedCategory}
          categories={categories}
          categoryChangeHandler={categoryChangeHandler}
        /> */}
        <Trade
          search={search}
          currentPage={currentPage}
          sortBy={sortBy}
          searchHandler={searchHandler}
          setSortBy={setSortBy}
          setCurrentPage={setCurrentPage}
          sortedMarkets={sortedMarkets}
          indexOfLastMarket={indexOfLastMarket}
          paginatedMarkets={paginatedMarkets}
          marketsPerPage={marketsPerPage}
          pageNumbers={pageNumbers}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default Details;
