import React, { memo } from "react";
import styles from "./MulCards.module.sass";
import SemiPieChart from "../../../../../components/SemiPieChart";
import { formatLargeValue, getDigitsAfterDecimal } from "../../../../../components/helper";

const DashboardCard = ({
    cmcData,
    usdPrice,
    selectedRegion
}) => {

    let marketCap = (usdPrice && cmcData?.total_market_cap) ? formatLargeValue(parseFloat(usdPrice) * parseFloat(cmcData?.total_market_cap)) : "";

    return (
        <div className={styles.coinCard}>
            <div className={styles.firstCard}>
                <div>Fear & Greed</div>
                <div>
                    <SemiPieChart value={cmcData} />
                </div>
            </div>

            <div className={styles.secondCard}>
                <div className={styles.box}>
                    <div className={styles.titleText}>Market Cap</div>
                    <div>{selectedRegion?.toLowerCase().includes("eu") ? `€${marketCap}` : `$${formatLargeValue(cmcData?.total_market_cap)}`}</div>
                    <div>
                        {cmcData && (cmcData?.total_market_cap_yesterday_percentage_change > 0 ? <span className={styles.positive}>&#9650;{getDigitsAfterDecimal(cmcData?.total_market_cap_yesterday_percentage_change, 2)}%</span> : <span className={styles.negative}>&#9660;{getDigitsAfterDecimal(cmcData?.total_market_cap_yesterday_percentage_change, 2)}%</span>)}
                    </div>
                </div>
                <div className={styles.box}>
                    <div className={styles.titleText}>Dominance</div>
                    <div className={styles.image}>
                        <img src="/images/content/btc.png" alt="icon" className={styles.icon} />
                        <div>{cmcData && getDigitsAfterDecimal(cmcData?.btc_dominance, 2)}%</div>
                    </div>
                    <div className={styles.image}>
                        <img src="/images/content/eth.png" alt="icon" className={styles.icon} />
                        <div>{cmcData && getDigitsAfterDecimal(cmcData?.eth_dominance, 2)}%</div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default memo(DashboardCard);

