import React, { useRef, useState } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Verification from "./Verification";
import NewPassword from "./NewPassword";
import Logo from "../../components/Logo";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
    const [acitveIndex, setAcitveIndex] = useState(0);
    const [email, setEmail] = useState("");
    const simpleValidator = useRef(new SimpleReactValidator({
        messages: {
            in: "New Password and Confirm password should be same!",
        }
    }));
    const [forceUpdate, setForceUpdate] = useState();
    const [loading, setLoading] = useState(false);
    const [securityCode, setSecurityCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [otp, setOtp] = useState("");
    const navigate = useNavigate();

    // Function to generate the OTP
    const genOtpHandler = async (email) => {
        setLoading(true);
        let data = {
            email: email,
            type: "reset_password",
            role: "customer",
        };
        try {
            const genOtpPayload = await requestHandler("gen_otp", "post", data);
            setLoading(false);
            if (genOtpPayload?.status === 201) {
                addNotification({
                    title: "Success",
                    message: "Otp has been sent.",
                    type: "sucess"
                });
                setAcitveIndex(1);
            }
        }
        catch (e) {
            addNotification({
                title: "Error",
                message: "Something went wrong, please try again later.",
                type: "danger",
            });
            setLoading(false);
        };
    };

    // Function to send the verification code
    const codeScreenHandler = async (code) => {
        setOtp(code);
        setLoading(true);
        let data = {
            email_otp: code,
            type: "reset_password",
            email: email,
        };
        try {
            const codeScreenPayload = await requestHandler("verify_otp", "post", data);
            setLoading(false);
            if (codeScreenPayload?.status === 200) {
                setAcitveIndex(2);
            }
            else {
                addNotification({
                    title: "Error",
                    message: "Something went wrong, please try again later.",
                    type: "danger",
                });
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: 'Error',
                message: e?.data?.message[0]?.msg,
                type: 'danger'
            });
        };
    };

    // Function to reset the password
    const resetPasswordHandler = async (email, password, confirmPassword) => {
        setLoading(true);
        let data = {
            email: email,
            password: password,
            c_password: confirmPassword,
            email_otp: otp
        };
        try {
            const resetPasswordPayload = await requestHandler("reset_password", "post", data);
            setLoading(false);
            if (resetPasswordPayload?.status === 200) {
                addNotification({
                    title: 'Success',
                    message: resetPasswordPayload?.data?.message[0]?.msg,
                    type: 'success'
                });
                navigate("/sign-in");
            }
            else {
                addNotification({
                    title: 'Error',
                    message: "Something went wrong, please try again later.",
                    type: 'danger'
                });
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: 'Error',
                message: e?.data?.message[0]?.msg,
                type: 'danger'
            });
        };
    };

    // Function to toggle password visibility
    const togglePasswordHandler = (e) => {
        e.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        };
        setPasswordType("password");
    };

    return (
        <>
            {loading && <LoaderScreen />}
            <Login
                content="Don’t have an account?"
                linkText="Sign up for free"
                linkUrl="/sign-up"
            >
                <Logo />
                {acitveIndex === 0 &&
                    <Form
                        email={email}
                        setEmail={setEmail}
                        simpleValidator={simpleValidator}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        genOtpHandler={genOtpHandler}
                    />
                }
                {acitveIndex === 1 && (
                    <Verification
                        email={email}
                        genOtpHandler={genOtpHandler}
                        securityCode={securityCode}
                        setSecurityCode={setSecurityCode}
                        codeScreenHandler={codeScreenHandler}
                        simpleValidator={simpleValidator}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                    />
                )}
                {acitveIndex === 2 &&
                    <NewPassword
                        password={password}
                        confirmPassword={confirmPassword}
                        setPassword={setPassword}
                        setConfirmPassword={setConfirmPassword}
                        simpleValidator={simpleValidator}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        togglePasswordHandler={togglePasswordHandler}
                        passwordType={passwordType}
                        resetPasswordHandler={resetPasswordHandler}
                        email={email}
                    />
                }
            </Login>
        </>
    );
};

export default ForgotPassword;
