import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Panel from "./Panel";
import Trade from "./Trade";

const Details = ({
  coinFee,
}) => {
  return (
    <div className={cn("section", styles.details)}>
      <div className={cn("container", styles.container)}>
        <Panel />
        <Trade
          coinFee={coinFee}
        />
      </div>
    </div>
  );
};

export default Details;
