import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import { Link } from "react-router-dom";
import TextInput from "../../../components/TextInput";

const Form = ({
  email,
  setEmail,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  genOtpHandler
}) => {
  return (
    <form className={styles.form}>
      <div className={styles.top}>
        <h3 className={cn("h3", styles.title)}>Forgot password</h3>
        <div className={styles.info}>
          For security purposes, no withdrawals are permitted for 24 hours after
          password changed.
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Enter the account email"
          name="email"
          type="email"
          placeholder="Your email"
          icon="email"
          required
          value={email}
          onChange={(e) => { setEmail(e.target.value) }}
          onBlur={() => {
            simpleValidator.current.showMessageFor("email");
          }}
        />
      </div>
      <span className={styles.danger}>
        {simpleValidator.current.message("email", email, "required")}
      </span>
      <button className={cn("button", styles.button)}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            genOtpHandler(email);
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Continue
      </button>
      <div className={styles.foot}>
        <Link className={styles.link} to="/sign-in">
          Nevermind, I got it
        </Link>
      </div>
    </form>
  );
};

export default Form;
