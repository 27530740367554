import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../../../components/Icon";
import Checkbox from "../../../components/Checkbox";
import TextInput from "../../../components/TextInput";
import CustomDropdown from "../../../components/CustomDropdown";
import { addNotification } from "../../../components/Notification";
import Loader from "../../../components/Loader";

const Form = ({
  email,
  setEmail,
  password,
  setPassword,
  regionText,
  setRegionText,
  regionValue,
  setRegionValue,
  captcha,
  setCaptcha,
  referralCode,
  setReferralCode,
  simpleValidator,
  forceUpdate,
  setForceUpdate,
  policy,
  setPolicy,
  regionData,
  captchaData,
  isEmailValid,
  setIsEmailValid,
  togglePasswordHandler,
  passwordType,
  confirmCaptchaHandler,
  loading
}) => {
  const base64data = btoa(unescape(encodeURIComponent(captchaData?.buffer)));

  return (
    <form className={styles.form}>
      <div className={styles.top}>
        <h3 className={cn("h4", styles.title)}>Sign up</h3>
        <div className={styles.info}>Please ensure you are visiting the correct url.</div>
        <div className={styles.correct}>
          <Icon name="lock" size="24" />
          <div className={styles.url}>
            https://<span>bitdenex.com/sign-up</span>
          </div>
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="email address"
          name="email"
          type="email"
          value={email}
          onChange={(e) => { setEmail(e.target.value) }}
          placeholder="Please enter email address"
          onBlur={() => {
            simpleValidator.current.showMessageFor("email");
            const newArr = process.env.REACT_APP_DISPOSABLE_EMAIL?.split(",");
            for (let index = 0; index < newArr.length; index++) {
              if (email?.split("@")[1]?.split(".")[0] === newArr[index]) {
                addNotification({
                  title: 'Alert',
                  message: "Disposable email is not allowed.",
                  type: 'danger'
                });
                setIsEmailValid(false);
                break;
              }
              else {
                setIsEmailValid(true);
              }
            }
          }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("email", email, "required")}
        </span>
        <TextInput
          className={styles.field}
          label="Password"
          name="password"
          type={passwordType}
          value={password}
          onChange={(e) => { setPassword(e.target.value) }}
          placeholder="Please enter password"
          view
          togglePassword={togglePasswordHandler}
          onBlur={() => {
            simpleValidator.current.showMessageFor("password");
          }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("password", password, "required|min:9")}
        </span>
        <CustomDropdown
          className={styles.field}
          label="Select Region"
          value={regionValue}
          setValue={setRegionValue}
          text={regionText}
          setText={setRegionText}
          options={regionData}
        />
        {!loading ? <img className={styles.captcha} src={`data:image/svg+xml;base64,${base64data}`} alt="captcha" /> : <Loader className={styles.loader} />}
        <TextInput
          className={styles.field}
          name="captcha"
          type="text"
          value={captcha}
          onChange={(e) => { setCaptcha(e.target.value) }}
          placeholder="Please enter captcha"
          onBlur={() => {
            simpleValidator.current.showMessageFor("captcha");
          }}
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("captcha", captcha, "required")}
        </span>
        <TextInput
          className={styles.field}
          label="referral code(optional)"
          name="referral"
          type="text"
          value={referralCode}
          onChange={(e) => { setReferralCode(e.target.value) }}
          placeholder="Please enter referral"
          onBlur={() => {
            simpleValidator.current.showMessageFor("referral");
          }}
        />
        <Checkbox
          className={styles.field}
          value={policy}
          onChange={() => {
            setPolicy(!policy);
            simpleValidator.current.showMessageFor("policy");
          }}
          content="<span>I agree that I’m 18+ years old, and accept the <a href='/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>, <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>, <a href='/kyc-policy' target='_blank' rel='noopener noreferrer'>KYC Policy</a>.<span>"
        />
        <span className={styles.danger}>
          {simpleValidator.current.message("policy", policy, "accepted")}
        </span>
      </div>
      <button
        className={cn("button", styles.button)}
        disabled={!isEmailValid}
        onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (regionText?.toLowerCase() !== "select region") {
              confirmCaptchaHandler(email, password, regionValue, captcha, referralCode, captchaData?.captchaId, policy);
            }
            else {
              addNotification({
                title: "Alert",
                message: "Please select region",
                type: "danger"
              });
            }
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}
      >
        Sign up
      </button>
    </form>
  );
};

export default Form;
