import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./Currency.module.sass";
import Icon from "../../../components/Icon";
import { getDigitsAfterDecimal } from "../../../components/helper";
import { Link } from "react-router-dom";

const Currency = ({
  marketSearch,
  setMarketSearch,
  handleSort,
  activeColumn,
  setCurrentMarketPrice,
  order,
  regions,
  currencies,
  selectedRegion,
  regionChangeHandler,
  selectedCurrency,
  currencyChangeHandler,
  selectedCurrencyData,
}) => {
  const searchLower = marketSearch.toLowerCase();

  const handleSorting = (columnName, data, order) => {
    if (!data?.length) return [];

    const sortingMap = {
      name: (a, b) => a?.name?.localeCompare(b.name),
      price: (a, b) => a?.currentMarketPrice - b?.currentMarketPrice,
      dayChange: (a, b) => a?.dayChange - b?.dayChange,
    };

    return [...data].sort((a, b) =>
      order === "ASC" ? sortingMap[columnName](a, b) : sortingMap[columnName](b, a)
    );
  };

  const sortedMarkets = useMemo(() => {
    if (!selectedCurrencyData?.markets) return [];

    let data = [...selectedCurrencyData.markets]?.sort((a, b) => a?.market_position - b?.market_position);

    return activeColumn?.key ? handleSorting(activeColumn.key, data, order) : data;
  }, [activeColumn, order, selectedCurrencyData]);

  const filteredMarkets = useMemo(() => {
    if (!marketSearch) return sortedMarkets;

    return sortedMarkets?.filter((x) =>
      x?.slug?.replace("-", "/")?.toLowerCase()?.includes(searchLower) ||
      x?.name?.toLowerCase()?.includes(searchLower)
    );
  }, [marketSearch, sortedMarkets]);

  return (
    <div className={styles.currency}>
      {/* {
        regions?.length > 1 &&
        <div className={styles.nav}>
          {regions?.map((region, index) => (
            <button
              key={index}
              className={cn(styles.link, { [styles.active]: region === selectedRegion })}
              onClick={() => regionChangeHandler(region)}
            >
              {region}
            </button>
          ))}
        </div>
      } */}

      <div className={styles.nav}>
        {currencies?.map((currency, index) => (
          <button
            key={index}
            className={cn(styles.link, { [styles.active]: currency.currency === selectedCurrency })}
            onClick={() => currencyChangeHandler(currency)}
          >
            {currency.currency}
          </button>
        ))}
      </div>

      <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
        <input
          className={styles.input}
          type="text"
          value={marketSearch}
          onChange={(e) => setMarketSearch(e.target.value)}
          placeholder="Search"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>

      <div className={styles.table}>
        <div className={styles.row}>
          {["name", "price", "dayChange"].map((col, index) => (
            <div key={index} className={styles.col}>
              <div
                className={cn("sorting", { [styles.activeColumn]: activeColumn.key === col })}
                onClick={() => handleSort(col)}
              >
                {col === "name" ? "Pair" : col === "price" ? "Price" : "Change"}
              </div>
            </div>
          ))}
        </div>

        {filteredMarkets?.map((x, index) => (
          <Link className={styles.row} key={index} to={`/exchange/${x.slug}`}>
            <div className={styles.col} onClick={() => setCurrentMarketPrice("")}>
              <div className={styles.line}>
                <div className={styles.info}>
                  <span>{x?.slug?.replace("-", "/")?.toUpperCase()}</span>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              {getDigitsAfterDecimal(x?.currentMarketPrice, x?.priceDecimalPrecision)}
            </div>
            <div className={styles.col}>
              <div className={x?.dayChange < 0 ? styles.negative : styles.positive}>
                {x?.dayChange < 0 ? "" : "+"}
                {parseFloat(x?.dayChange)?.toFixed(2)}%
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Currency;
