import {
    CMC_DATA_REQUEST,
    CMC_DATA_SUCCESS,
    CMC_DATA_FAILED,
} from '../actions/cmcData/actionType'

const initialState = {
    loading: false,
    cmcData: [],
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CMC_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CMC_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                cmcData: action?.payload?.data?.data?.[0],
            }
        case CMC_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    };
};

export default reducer;