import React from "react";
import styles from "./Trade.module.sass";
import { getDigitsAfterDecimal } from "../../../../../components/helper";

const Trade = ({
    coinFee
}) => {

    const sortedCoinFee = coinFee?.sort((a, b) => {
        const symbolA = a.symbol?.toUpperCase() || '';
        const symbolB = b.symbol?.toUpperCase() || '';

        if (symbolA < symbolB) return -1;
        if (symbolA > symbolB) return 1;
        return 0;
    });

    return (
        <div className={styles.trade}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>Coin</div>
                    <div className={styles.col}>Network</div>
                    <div className={styles.col}>Withdrawal Fee</div>
                </div>
                {sortedCoinFee?.map((x, index) => (
                    <div className={styles.row} key={index}>
                        <div className={styles.col}>
                            {`${x.name?.toUpperCase()}(${x.symbol?.toUpperCase()})`}
                        </div>
                        <div className={styles.col}>
                            {/* {x.network_slug === null ? `${x.name}` : `${x.network_slug} - ${x.name}`} */}
                            {x.network_slug === null ? `${x.name}` : `${x.network_slug}`}
                        </div>
                        <div className={styles.col}>
                            {getDigitsAfterDecimal(x.maxfee, x.decimalPrecision)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Trade;
