import React, { useCallback } from "react";
import styles from "./Panel.module.sass";
import Card from "./Card";
import MulCards from "./MulCards";

const Panel = ({
  recentMarkets,
  topGainers,
  topLosers,
  topVolume,
  cmcData,
  selectedCurrency,
  usdPrice,
  recentAdded,
  selectedRegion
}) => {

  const renderMultiCard = useCallback(() => {
    return <MulCards
      cmcData={cmcData}
      usdPrice={usdPrice}
      selectedCurrency={selectedCurrency}
      selectedRegion={selectedRegion}
    />
  }, [cmcData, selectedRegion, usdPrice]);

  return (
    <div className={styles.coinMarket}>
      <div className={styles.flexContainer}>
        <Card coins={recentAdded} title={`Recently Added`} />
        <Card topGainers={topGainers} topLosers={topLosers} title={`Top Gainers`} filter={true} />
        {/* <Card coins={topLosers} title={`Top Losers`} /> */}
        <Card coins={topVolume} title={`Top Volume`} />
        {renderMultiCard()}
      </div>
    </div>
  );
};
export default Panel;