import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("h3", styles.title)}>
            Whistleblower Procedure
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;