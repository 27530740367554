import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Dropdown from "../../../../components/Dropdown";

const Panel = ({
  selectedCurrency,
  regionChangeHandler,
  selectedRegion,
  regions,
  currencies,
  currencyChangeHandler
}) => {

  return (
    <>
      {parseInt(process.env.REACT_APP_WARNING_TEXT_MARKET_PAGE) === 1 &&
        <div className={styles.warningPanelContainer}>
          <div className={styles.warning}>
            The offered data is solely intended for informational reasons. This
            information is provided as is, with no warranties or assurances, and
            some of this is sourced from CoinMarketCap. Be mindful of the risks involved.
          </div>
        </div>
      }
      <div className={styles.panelContainer}>
        <div className={styles.footerContainer}>
          <div className={styles.navContainer}>
            {currencies?.map((currency, index) => (
              <button
                className={cn(styles.navLink, {
                  [styles.activeNavLink]: currency.currency === selectedCurrency,
                })}
                onClick={() => { currencyChangeHandler(currency.currency) }}
                key={index}
              >
                {currency.currency}
              </button>
            ))}
          </div>
          {/* {regions?.length > 1 &&
            <div className={styles.dropdown}>
              <Dropdown
                value={selectedRegion}
                setValue={regionChangeHandler}
                options={regions}
              />
            </div>
          } */}
        </div>
      </div>
    </>
  );
};
export default Panel;