import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./SignIn.module.sass";
import Login from "../../components/Login";
import Form from "./Form";
import Scan from "./Scan";
import Code from "./Code";
import Confirm from "./Confirm";
import Barcode from "./Barcode";
import Logo from "../../components/Logo";
import SimpleReactValidator from "simple-react-validator";
import requestHandler from "../../actions/httpClient";
import LoaderScreen from "../../components/LoaderScreen";
import { addNotification } from "../../components/Notification";
import { io } from "socket.io-client";

const SignIn = () => {
  const [scan, setScan] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [activeIndex, setActiveIndex] = useState(0);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [captchaData, setCaptchaData] = useState([]);
  const [securityCode, setSecurityCode] = useState("");
  const [signinData, setSigninData] = useState([]);
  const [barcodeSecurityCode, setBarcodeSecurityCode] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [qrCodeData, setQrCodeData] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [qrCodeLogin, setQrCodeLogin] = useState(false);
  const [showQrCode, setShowQrCode] = useState(true);
  const [count, setCount] = useState(0);

  // Function to toggle password visibility
  const togglePasswordHandler = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    };
    setPasswordType("password");
  };

  const captchaHandler = useCallback(async () => {
    setLoading(true);
    const data = {
      theme: localStorage.getItem('darkMode')
    }
    try {
      const captchPayload = await requestHandler("createCaptcha", 'post', data);
      setCaptchaData(captchPayload?.data)
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  }, []);

  const getQrCodeHandler = useCallback(async () => {
    setLoading(true);
    try {
      const getQrCodePayload = await requestHandler("generateQRLogin", "post");
      setLoading(false);
      setQrCodeData(getQrCodePayload?.data?.data?.qrCode);
      setSessionId(getQrCodePayload?.data?.data?.sessionId);
    }
    catch (e) {
      setLoading(false);
    };
  }, []);

  const loginHandler = async (email, password, captcha, cId) => {
    setLoading(true);
    let captchaData = {
      id: cId,
      code: captcha
    };
    try {
      const captchaPayload = await requestHandler("verifyCaptcha", 'post', captchaData);
      if (captchaPayload?.status === 200) {
        let data = {
          email: email,
          password: password
        };
        const signinPayload = await requestHandler("login", 'post', data);
        setSigninData(signinPayload);
        if (signinPayload?.status === 200) {
          setLoading(false);
          setActiveIndex(1);
        }
        else if (signinPayload?.status === 201) {
          setLoading(false);
          setActiveIndex(2);
        }
        else if (signinPayload?.status === 202) {
          setLoading(false);
          setActiveIndex(3);
        }
      }
    }
    catch (e) {
      setCaptcha("");
      await captchaHandler();
      setLoading(false);
      addNotification({
        title: "Error",
        message: e?.data?.message?.[0]?.msg,
        type: "danger",
      });
    };
  };

  const verifyBarcodeHandler = async (code) => {
    setLoading(true);
    let data = {
      email: email,
      code: code,
    };
    try {
      const verifyBarcodePayload = await requestHandler("verifyLogin", "post", data);
      setLoading(false);
      if ((verifyBarcodePayload.status === 200 && verifyBarcodePayload?.data?.data?.[0]?.role === process.env.REACT_APP_VALID_ROLE) ||
        (verifyBarcodePayload.status === 200 && verifyBarcodePayload?.data?.data?.[0]?.role === process.env.REACT_APP_CORPORATE_ROLE)) {
        if (verifyBarcodePayload?.data?.data?.[0]?.signature) {
          var signature = verifyBarcodePayload?.data?.data[0]?.signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = verifyBarcodePayload?.data?.data?.[0]?.url + '?sig=' + signature;
        }
        else {
          window.location.href = verifyBarcodePayload?.data?.data?.[0]?.url;
        }
      }
      else {
        addNotification({
          title: 'Error',
          message: `You dont't have valid access`,
          type: 'danger'
        });
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    };
  };

  const codeScreenHandler = async (code) => {
    setLoading(true);
    let data = {
      email_otp: code,
      email: email,
      type: signinData.data.message.type,
    };
    try {
      const codeScreenPayload = await requestHandler("verify_otp", "post", data);
      setSigninData(codeScreenPayload);
      setLoading(false);
      if (codeScreenPayload?.status === 200) {
        setActiveIndex(1);
      }
      else if (codeScreenPayload?.status === 202) {
        setActiveIndex(3);
      }
    }
    catch (e) {
      setLoading(false);
      addNotification({
        title: 'Error',
        message: e.data.message[0].msg,
        type: 'danger'
      });
    };
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    setLoading(true);
    socket.connect();
    if (sessionId !== "") {
      setLoading(false);
      socket.on("connect", () => {
      });

      socket.on('loginWebhook_' + sessionId, function (result) {
        if (result[0].signature) {
          var signature = result[0].signature;
          signature = signature.replace('+', '_');
          signature = signature.replace('/', '-');
          window.location.href = result[0].url + '?sig=' + signature;
        }
      });
    }

    return () => {
      socket.disconnect();
    }
  }, [sessionId]);

  useEffect(() => {
    if (scan) {
      const refreshInterval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      if (count === 60) {
        clearInterval(refreshInterval);
        setShowQrCode(false);
      }
      return () => clearInterval(refreshInterval);
    }
  }, [count, scan]);

  useEffect(() => {
    if (scan) {
      getQrCodeHandler();
    }
  }, [getQrCodeHandler, scan]);

  useEffect(() => {
    captchaHandler();
  }, [captchaHandler]);

  return (
    <>
      {loading && <LoaderScreen />}
      <Login
        content="Don’t have an account?"
        linkText="Sign up for free"
        linkUrl="/sign-up"
      >
        <Logo />
        <div className={styles.login}>
          {scan ?
            <Scan
              showQrCode={showQrCode}
              getQrCodeHandler={getQrCodeHandler}
              setQrCodeLogin={setQrCodeLogin}
              setCount={setCount}
              setShowQrCode={setShowQrCode}
              setSessionId={setSessionId}
              qrCodeLogin={qrCodeLogin}
              qrCodeData={qrCodeData}
              goPrev={() => { setScan(false) }}
              count={count}
              scan={scan}
            />
            :
            activeIndex === 0 ?
              <Form
                onScan={() => setScan(true)}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                captcha={captcha}
                setCaptcha={setCaptcha}
                passwordType={passwordType}
                simpleValidator={simpleValidator}
                forceUpdate={forceUpdate}
                setForceUpdate={setForceUpdate}
                togglePasswordHandler={togglePasswordHandler}
                captchaData={captchaData}
                loading={loading}
                loginHandler={loginHandler}
              />
              :
              activeIndex === 1 ?
                <Barcode
                  signinData={signinData}
                  verifyBarcodeHandler={verifyBarcodeHandler}
                  barcodeSecurityCode={barcodeSecurityCode}
                  setBarcodeSecurityCode={setBarcodeSecurityCode}
                  simpleValidator={simpleValidator}
                  forceUpdate={forceUpdate}
                  setForceUpdate={setForceUpdate}
                />
                :
                activeIndex === 2 ?
                  <Code
                    securityCode={securityCode}
                    setSecurityCode={setSecurityCode}
                    simpleValidator={simpleValidator}
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    email={email}
                    codeScreenHandler={codeScreenHandler}
                  />
                  :
                  activeIndex === 3 ?
                    <Confirm
                      verifyBarcodeHandler={verifyBarcodeHandler}
                      simpleValidator={simpleValidator}
                      forceUpdate={forceUpdate}
                      setForceUpdate={setForceUpdate}
                      confirmationCode={confirmationCode}
                      setConfirmationCode={setConfirmationCode}
                    />
                    :
                    ""
          }
        </div>
      </Login>
    </>
  );
};

export default SignIn;
