import React from "react";
import cn from "classnames";
import styles from "./Trade.module.sass";
import Icon from "../../../../components/Icon";

const Trade = ({
  coinFee,
  search,
  handleSearch
}) => {

  return (
    <div className={styles.trade}>
      <form className={styles.form}>
        <input
          value={search}
          className={styles.input}
          type="text"
          onChange={handleSearch}
          name="search"
          placeholder="Search coin"
          required
        />
        <button onClick={(e) => { e.preventDefault(); }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>#</div>
          <div className={styles.col}>
            COIN
          </div>
          <div className={styles.col}>
            NETWORK
          </div>
          <div className={styles.col}>
            DEPOSITS
          </div>
          <div className={styles.col}>
            WITHDRAWS
          </div>
          <div className={styles.col}>
            TRADE
          </div>
        </div>
        {coinFee?.filter((x) => {
          const searchText = `${x?.name?.toUpperCase()} ${x?.symbol?.toUpperCase()} ${x?.network_name?.toUpperCase()}`;
          return searchText?.includes(search?.toUpperCase());
        }).map((i, ind) => {
          return (
            <div className={styles.row} key={ind}>
              <div className={styles.col}>
                <div className={styles.line}>{ind + 1}</div>
              </div>
              <div className={styles.col}>
                {`${i.name?.toUpperCase()}(${i.symbol?.toUpperCase()})`}
              </div>
              <div className={styles.col}>
                {i.network_name === null ? `${i.name?.toUpperCase()}` : `${i.network_name}`}
              </div>
              <div className={styles.col}>
                {i?.enable_deposit === parseInt(process.env.REACT_APP_DEPOSIT_ENABLE) ?
                  <button className={styles.supportButton}>
                    Enabled
                  </button>
                  :
                  <button className={styles.disabledButton}>
                    Disabled
                  </button>
                }
              </div>
              <div className={styles.col}>
                {i?.enable_withdraw === parseInt(process.env.REACT_APP_WITHDRAW_ENABLE) ?
                  <button className={styles.supportButton}>
                    Enabled
                  </button>
                  :
                  <button className={styles.disabledButton}>
                    Disabled
                  </button>
                }
              </div>
              <div className={styles.col}>
                <span className={styles.checkMark}>
                  ✓
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trade;
