import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Main from "./Main";
import Details from "./Details";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { socketDataReceived } from "../../actions/markets";
import LoaderScreen from "../../components/LoaderScreen";
import { cmcDataCreator } from "../../actions/cmcData";

const Market = () => {
  const { socketMarketData } = useSelector((state) => { return state.markets });
  const { cmcData } = useSelector((state) => { return state.cmcData });
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [usdPrice, setUsdPrice] = useState();
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({ field: null, order: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const marketsPerPage = parseInt(process.env.REACT_APP_MARKET_PAGE_PAGINATION_COUNT);

  const regions = [...new Set(socketMarketData?.flat()?.map((item) => item?.region_name))];

  const currencies = socketMarketData?.flat()?.filter((item) => item?.region_name === selectedRegion);

  const selectedCurrencyData = currencies?.find((item) => item?.currency === selectedCurrency);

  const categories = selectedCurrencyData?.category?.length
    ? ["All", ...selectedCurrencyData?.category?.map((cat) => cat?.category)]
    : ["All"];

  const filteredMarkets =
    selectedCategory === "All" || !selectedCurrencyData?.category?.length
      ? selectedCurrencyData?.markets || []
      : selectedCurrencyData?.category?.find((cat) => cat?.category === selectedCategory)?.markets || [];

  const searchedMarkets = filteredMarkets?.filter((market) =>
    market?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
    market?.slug?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const sortedMarkets = useMemo(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return searchedMarkets;
    }

    if (!sortBy || !sortBy?.field) return searchedMarkets;

    return [...searchedMarkets]?.sort((a, b) => {
      const fieldKey = sortBy?.field;

      if (fieldKey === "name") {
        return sortBy.order === "asc"
          ? a?.name?.localeCompare(b?.name)
          : b?.name?.localeCompare(a?.name);
      } else {
        const valA = parseFloat(a[fieldKey]);
        const valB = parseFloat(b[fieldKey]);

        return sortBy.order === "asc" ? valA - valB : valB - valA;
      }
    });
  }, [searchedMarkets, sortBy]);

  const indexOfLastMarket = currentPage * marketsPerPage;
  const indexOfFirstMarket = indexOfLastMarket - marketsPerPage;
  const paginatedMarkets = sortedMarkets?.slice(indexOfFirstMarket, indexOfLastMarket);

  const totalPages = Math.ceil(sortedMarkets.length / marketsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (!selectedRegion && regions?.length) {
      setSelectedRegion(regions[0]);
    }
    if (selectedRegion?.toLowerCase()?.includes("eu")) {
      let price = socketMarketData?.[0]?.[0]?.markets?.find(x => x.symbol?.toLowerCase() === "usdc")?.currentMarketPrice;
      setUsdPrice(price);
    }
  }, [regions, selectedRegion]);

  useEffect(() => {
    if (currencies.length) {
      setSelectedCurrency(currencies[0].currency);
    }
    setCurrentPage(1);
  }, [selectedRegion]);

  useEffect(() => {
    if (cmcData?.length == 0) {
      dispatch(cmcDataCreator());
    }
  }, [cmcData]);

  useEffect(() => {

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    let marketSocket = "new_all_markets_web";
    socket.connect();
    const marketData = "all_markets";
    socket.on("connect", () => {
      socket.emit("markets", marketData);
    })

    socket.on(marketSocket, (markets) => {
      dispatch(socketDataReceived(markets));
      socket.disconnect();
    });

    const interval = setInterval(() => {
      socket.connect()
      socket.emit("markets", marketData);
    }, parseInt(process.env.REACT_APP_MARKET_SOCKET_TIME_INTERVAL));

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, []);

  const renderMain = useCallback(() => {
    return <Main
      cmcData={cmcData}
      usdPrice={usdPrice}
      selectedRegion={selectedRegion}
    />
  }, [cmcData, selectedRegion, usdPrice]);

  const regionChangeHandler = (value) => {
    setSelectedRegion(value);
    setSelectedCurrency("");
    setSelectedCategory("All");
  };

  const currencyChangeHandler = (value) => {
    setSelectedCurrency(value);
    setSelectedCategory("All");
    setCurrentPage(1);
  };

  const categoryChangeHandler = (value) => {
    setSelectedCategory(value)
  };

  const searchHandler = (event) => {
    setSearch(event.target.value);
  };

  const topGainers = useMemo(() => {
    return [...filteredMarkets]
      .filter((coin) => coin?.dayChange > 0)
      .sort((a, b) => (parseFloat(b?.dayChange)) - (parseFloat(a?.dayChange)))
      .slice(0, 5);
  }, [filteredMarkets]);

  const topLosers = useMemo(() => {
    return [...filteredMarkets]
      .filter((coin) => coin?.dayChange < 0)
      .sort((a, b) => (parseFloat(a?.dayChange)) - (parseFloat(b?.dayChange)))
      .slice(0, 5);
  }, [filteredMarkets]);

  const topVolume = useMemo(() => {
    return [...filteredMarkets]
      .sort((a, b) => {
        const volumeA = parseFloat(a?.volume?.replace(/,/g, ''));
        const volumeB = parseFloat(b?.volume?.replace(/,/g, ''));
        return volumeB - volumeA;
      })
      .slice(0, 5);
  }, [filteredMarkets]);

  const recentAdded = useMemo(() => {
    return [...filteredMarkets]
      .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
      .slice(0, 5);
  }, [filteredMarkets]);

  return (
    <>
      {socketMarketData <= 0 && <LoaderScreen />}
      {renderMain()}
      <Details
        regionChangeHandler={regionChangeHandler}
        selectedRegion={selectedRegion}
        regions={regions}
        currencyChangeHandler={currencyChangeHandler}
        currencies={currencies}
        selectedCurrency={selectedCurrency}
        categories={categories}
        categoryChangeHandler={categoryChangeHandler}
        selectedCategory={selectedCategory}
        search={search}
        searchHandler={searchHandler}
        paginatedMarkets={paginatedMarkets}
        sortBy={sortBy}
        setSortBy={setSortBy}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        sortedMarkets={sortedMarkets}
        indexOfLastMarket={indexOfLastMarket}
        marketsPerPage={marketsPerPage}
        topGainers={topGainers}
        topLosers={topLosers}
        topVolume={topVolume}
        recentAdded={recentAdded}
        cmcData={cmcData}
        usdPrice={usdPrice}
        pageNumbers={pageNumbers}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
    </>
  );
};

export default Market;
