import React from "react";
import cn from "classnames";
import styles from "./Notice.module.sass";

const Notice = ({ className }) => {
  return (
    <>
      <div className={cn(className, styles.cards)}>
        <div className={styles.details}>
          <div className={styles.price}>The risk of investing</div>
          <div className={styles.money}>Bitdenex.com is a website which provides an overview of the trading options and trading
            platform of Bitdenex per region. Each region applies its own local applicable law and
            regulations to the local traders, which shall comply with the applicable law.
          </div>
          {/* <br /> */}
          {/* <div className={styles.money}>Bitdenex International is operated by Coindenex LLC - license no. 922 LLC 2021, which
            is authorized and regulated by the Financial Services Authority of St. Vincent & The
            Grenadines.
          </div> */}
          <br />
          <div className={styles.money}>Bitdenex Europe is operated by Bitdenex B.V. - license no. R179357, which is registered,
            authorized, and regulated as a provider of crypto services by the Dutch Central bank, De
            Nederlandsche Bank N.V. (“DNB”). DNB oversees Bitdenex B.V. of the Money Laundering
            and Terrorist Financing Prevention Act and the Sanctions Act 1977. Bitdenex B.V. is not
            subject to prudential supervision by DNB or supervised by the AFM. This means that there is
            no supervision on financial requirements of business risks and there are no specific financial
            consumer protection.
          </div>
          <br />
          {/* <div className={styles.money}>
            Bitdenex Suriname is operated by Bitdenex Suriname N.V., registered under the Chamber of Commerce & Industry no. 92774, incorporated under the laws of Suriname.
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Notice;
