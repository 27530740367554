import React, { useCallback, useEffect, useRef, useState } from "react";
import Login from "../../components/Login";
import Form from "./Form";
import Confirm from "./Confirm";
import Code from "./Code";
import Barcode from "./Barcode";
import Logo from "../../components/Logo";
import SimpleReactValidator from "simple-react-validator";
import LoaderScreen from "../../components/LoaderScreen";
import requestHandler from "../../actions/httpClient";
import { addNotification } from "../../components/Notification";
import { useLocation } from "react-router";

const SignUp = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [regionText, setRegionText] = useState("Select Region");
    const [regionValue, setRegionValue] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [referralCode, setReferralCode] = useState(window.location.href.split("ref=")[1]);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [forceUpdate, setForceUpdate] = useState();
    const [policy, setPolicy] = useState(false);
    const [loading, setLoading] = useState(false);
    const [regionData, setRegionData] = useState([]);
    const [captchaData, setCaptchaData] = useState();
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [signupToken, setSignupToken] = useState([]);
    const [passwordType, setPasswordType] = useState("password");
    const [securityCode, setSecurityCode] = useState("");
    const [authenticator, setAuthenticator] = useState("");
    const [userData, setUserData] = useState({});
    const [signupData, setSigupData] = useState([]);
    const [codeSuccessData, setCodeSuccessData] = useState([]);
    const location = useLocation();

    // Code to receive the email entered by the user on the home page
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('username');
        if (emailParam) {
            setEmail(emailParam);
            const disposableDomains = process.env.REACT_APP_DISPOSABLE_EMAIL?.split(",");
            let isValid = true;
            const emailDomain = emailParam?.split("@")[1]?.split(".")[0];
            for (let i = 0; i < disposableDomains?.length; i++) {
                if (emailDomain === disposableDomains?.[i]?.trim()) {
                    addNotification({
                        title: 'Alert',
                        message: "Disposable email is not allowed.",
                        type: 'danger'
                    });
                    setIsEmailValid(false);
                    isValid = false;
                    break;
                }
            }
            if (isValid) {
                setIsEmailValid(true);
            }
        }
        else {
            setEmail('');
            setIsEmailValid(true);
        }
    }, [location.search]);

    // Function to toggle password visibility
    const togglePasswordHandler = (e) => {
        e.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        };
        setPasswordType("password");
    };

    // Function to fetch region data
    const getRegionDataHandler = useCallback(async () => {
        setLoading(true);
        try {
            const regionPayload = await requestHandler("region", "post");
            setLoading(false);
            let regionData = regionPayload?.data?.data?.map((x) => {
                return { "cId": x.id, "cValue": x.name }
            });
            setRegionData([...regionData]);
        }
        catch (e) {
            setLoading(false);
        }
    }, []);

    // Function to fetch captcha
    const captchaHandler = useCallback(async () => {
        setLoading(true);
        const data = {
            theme: localStorage.getItem('darkMode')
        }
        try {
            const captchPayload = await requestHandler("createCaptcha", 'post', data);
            setCaptchaData(captchPayload?.data)
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        };
    }, []);

    // Function to retrieve signup token
    const getSignupTokenHandler = useCallback(async () => {
        setLoading(true);
        try {
            const signupTokenPayload = await requestHandler("signuptoken");
            setLoading(false);
            setSignupToken(signupTokenPayload?.data?.data);
        }
        catch (e) {
            setLoading(false);
        };
    }, []);

    // Function to validate captcha
    const confirmCaptchaHandler = async (email, password, regionValue, captcha, referralCode, captchaId, policy) => {
        setLoading(true);
        let data = {
            id: captchaId,
            code: captcha
        };
        const userData = {};
        userData.policy = policy;
        userData.email = email;
        userData.password = password;
        userData.ciphertext = `${signupToken.value}${signupToken.id}`;
        userData.id = signupToken.id;
        userData.region = regionValue;
        userData.refferal_code = referralCode;
        setUserData(userData);
        try {
            const confirmCaptchaPayload = await requestHandler("verifyCaptcha", "post", data,);
            setLoading(false);
            if (confirmCaptchaPayload?.status === 200 && isEmailValid) {
                setActiveIndex(1);
                setCaptcha("");
                await captchaHandler();
            }
            else {
                addNotification({
                    title: 'Alert',
                    message: "Disposable email is not allowed.",
                    type: 'danger'
                });
                setCaptcha("");
                await captchaHandler();
            }
        }
        catch (e) {
            addNotification({
                title: 'Error',
                message: e.data.message[0].msg,
                type: 'danger'
            });
            setLoading(false);
            setCaptcha("");
            await captchaHandler();
        };
    };

    // Function to validate signup process
    const signupHandler = async () => {
        setLoading(true);
        try {
            const signupPayload = await requestHandler("signup", "post", userData);
            setLoading(false);
            setSigupData(signupPayload);
            if (signupPayload?.status === 201) {
                setActiveIndex(2);
            }
        }
        catch (e) {
            addNotification({
                title: 'Error',
                message: e.data.message[0].msg,
                type: 'danger'
            });
            setLoading(false);
        };
    };

    // Function to validate code entry process
    const codeScreenHandler = async (code) => {
        setLoading(true);
        let data = {
            email_otp: code,
            email: userData.email,
            type: signupData.data.message.type,
            refferal_code: signupData.data.message.data.refferal_code,
        };
        try {
            const codeScreenPayload = await requestHandler("verify_otp", "post", data);
            setCodeSuccessData(codeScreenPayload);
            setLoading(false);
            if (codeScreenPayload.status === 200) {
                setActiveIndex(3);
            }
            else {
                addNotification({
                    title: "Error",
                    message: "Something went wrong, please try again.",
                    type: "danger"
                });
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: e.data.message[0].msg,
                type: "danger"
            });
        };
    };

    // Function to validate barcode scanning process
    const barcodeScreenHandler = async (code) => {
        setLoading(true);
        let data = {
            email: userData.email,
            code: code,
        };
        try {
            const barcodeScreenPayload = await requestHandler("verifyLogin", "post", data);
            setLoading(false);
            if (barcodeScreenPayload.status === 200) {
                if (barcodeScreenPayload.data.data?.[0]?.signature) {
                    var signature = barcodeScreenPayload?.data?.data?.[0]?.signature;
                    signature = signature.replace('+', '_');
                    signature = signature.replace('/', '-');
                    window.location.href = barcodeScreenPayload?.data?.data?.[0]?.url + '?sig=' + signature;
                }
                else {
                    window.location.href = barcodeScreenPayload?.data?.data?.[0]?.url;
                }
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: 'Error',
                message: e.data.message[0].msg,
                type: 'danger'
            });
        };
    };

    // Code to invoke the region function
    useEffect(() => {
        getRegionDataHandler();
    }, [getRegionDataHandler]);

    // Code to invoke the captcha function
    useEffect(() => {
        captchaHandler();
    }, [captchaHandler]);

    // Code to invoke the signup token function
    useEffect(() => {
        getSignupTokenHandler();
    }, [getSignupTokenHandler]);

    return (
        <>
            {loading && <LoaderScreen />}
            <Login
                content="Already have an account?"
                linkText="Login"
                linkUrl="/sign-in"
            >
                <Logo />
                {activeIndex === 0 && <Form
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    regionText={regionText}
                    setRegionText={setRegionText}
                    regionValue={regionValue}
                    setRegionValue={setRegionValue}
                    captcha={captcha}
                    setCaptcha={setCaptcha}
                    referralCode={referralCode}
                    setReferralCode={setReferralCode}
                    simpleValidator={simpleValidator}
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    policy={policy}
                    setPolicy={setPolicy}
                    regionData={regionData}
                    captchaData={captchaData}
                    isEmailValid={isEmailValid}
                    setIsEmailValid={setIsEmailValid}
                    togglePasswordHandler={togglePasswordHandler}
                    passwordType={passwordType}
                    confirmCaptchaHandler={confirmCaptchaHandler}
                    loading={loading}
                />}
                {activeIndex === 1 &&
                    <Confirm
                        goBack={() => {
                            setActiveIndex(0);
                            setCaptcha("");
                        }}
                        email={email}
                        signupHandler={signupHandler}
                    />
                }
                {activeIndex === 2 &&
                    <Code
                        email={email}
                        setSecurityCode={setSecurityCode}
                        simpleValidator={simpleValidator}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        securityCode={securityCode}
                        codeScreenHandler={codeScreenHandler}
                    />
                }
                {activeIndex === 3 &&
                    <Barcode
                        email={email}
                        setSecurityCode={setSecurityCode}
                        simpleValidator={simpleValidator}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        authenticator={authenticator}
                        setAuthenticator={setAuthenticator}
                        barcodeScreenHandler={barcodeScreenHandler}
                        codeSuccessData={codeSuccessData}
                    />
                }
            </Login>
        </>
    );
};

export default SignUp;
