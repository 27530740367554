import React from "react";
import cn from "classnames";
import styles from "./Article.module.sass";

const Article = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          <h4>
            Secure Submission Guidelines
          </h4>
          <h4>
            For Whistleblowers
          </h4>
          <p>
            To ensure your security and anonymity when submitting sensitive information, please follow these guidelines:
          </p>
          <p>
            Accessing the Platform Securely
          </p>
          <p>
            1. Download and install the Tor Browser from <a href="https://www.torproject.org/download/" target="_blank" rel="noopener noreferrer"><button className={styles.supportButton}>Link</button></a>
          </p>
          <p>
            2. Access our secure submission platform ONLY through Tor Browser at:
          </p>
          <p>
            <a href="http://mpjldaml5w63ndngygosxecho7fyhl3fxpk3izhzb5hm7zlur2n54mid.onion/embed/98fe2b4c-27cc-42ee-8999-5819954bc870" target="_blank" rel="noopener noreferrer"><button className={styles.supportButton}>Link</button></a>
          </p>
          <p>
            3. Before making your submission:
          </p>
          <p className={styles.text}>
            &#8226;
            Set Tor Browser's Security Level to "Safest"
          </p>
          <p className={styles.text}>
            &#8226;
            Disable JavaScript using the shield icon in the toolbar
          </p>
          <p className={styles.text}>
            &#8226;
            Use a secure internet connection, preferably not your home or work network
          </p>
          <h4>
            Important Security Notes
          </h4>
          <p>
            &#8226;
            ONLY make submissions through Tor Browser using our .onion address
          </p>

          <p>
            &#8226;
            <a href="https://bitdenex-ethics.yokai.network/embed/98fe2b4c-27cc-42ee-8999-5819954bc870" target="_blank" rel="noopener noreferrer"><button className={styles.supportButton}>Link</button></a>
          </p>
          <p>
            &#8226;
            Never use regular browsers or the <a href="https://bitdenex-ethics.yokai.network/embed/98fe2b4c-27cc-42ee-8999-5819954bc870" target="_blank" rel="noopener noreferrer"><button className={styles.supportButton}>Link</button></a> for sensitive submissions
          </p>
          <p>
            &#8226;
            Consider using a public Wi-Fi network and maintaining physical distance from the submission location
          </p>
          <p>
            &#8226; Do not access this platform from your work computer or network
          </p>
          <p>
            &#8226; Avoid discussing your submissions through email or other traceable communications
          </p>
          <p>
            &#8226; Never share your codename or any details about your submission via insecure channels
          </p>
          <h4>
            Best Practices
          </h4>
          <p>
            &#8226; Keep your codename safe and confidential
          </p>
          <p>
            &#8226; Clear your Tor Browser session after each submission by clicking the "New Identity" button
          </p>
          <p>
            &#8226; For large files over 100MB, please first submit a message to request alternative transfer methods
          </p>
          <p>
            &#8226; If you need to make multiple submissions, use the same codename to maintain conversation continuity
          </p>
          <h4>
            After Submission
          </h4>
          <p>
            &#8226; Regularly check for responses using your codename
          </p>
          <p>
            &#8226; Always use Tor Browser for all communications
          </p>
          <p>
            &#8226; Never reveal your identity or submission details on other platforms
          </p>
          <p>
            For regular access to the platform (non-whistleblowers), please use our standard website at <a href="https://bitdenex-ethics.yokai.network/embed/98fe2b4c-27cc-42ee-8999-5819954bc870" target="_blank" rel="noopener noreferrer"><button className={styles.supportButton}>Link</button></a>
          </p>
          <p>
            <span className={styles.spanText}>Note:</span> This platform is designed to protect your identity when accessed through Tor Browser. However, please be aware that no system can guarantee 100% security. Exercise caution and good judgment when submitting sensitive information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Article;